import { countries } from '../../countries';
import CustomSelect from '../CustomSelectOld';
import { ArrowDown, CloseCircle, CloseIcon } from '@/assets/svg';
import {
  MenuItem,
  Box,
  withStyles,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  InputAdornment,
  IconButton,
  List,
  ListItem,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useMemo, useState } from 'react';

const initCountry = 'SG';

const CustomTextField = withStyles({
  root: {
    fontSize: 20,
    width: '100%',

    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: 8,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      padding: '8px 5px',
      fontSize: 16,
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },

    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
  },
})(TextField);

const CustomCountryCodeInput = ({
  country = initCountry,
  onChange,
  disabled,
  isMobileSize = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const [selectedItems, setSelectedItems] = useState('');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    setSelectedItems('');
    setSearchKey('');
  };

  const handleListItemClick = (event, value) => {
    onChange(value);
    setSelectedItems(value);
    handleClose(event);
  };

  const filterData = useMemo(() => {
    let list = Object.keys(countries).filter(
      (item) =>
        countries[item].name.common
          .toLowerCase()
          ?.includes(searchKey.toLowerCase()) ||
        countries[item]?.callingCode[0]?.includes(searchKey)
    );

    const filtered = Object.keys(countries)
      .filter((key) => list.includes(key))
      .reduce((obj, key) => {
        obj[key] = countries[key];
        return obj;
      }, {});

    return filtered;
  }, [searchKey]);

  return (
    <Box>
      {isMobileSize ? (
        <CustomSelect
          isFullWidth
          isShowArrow
          onClick={handleClick}
          renderValue={
            <Box display="flex" alignItems="center" color={'#333'}>
              <img
                src={countries[country]?.flag}
                alt={countries[country]?.name?.common || ''}
                style={{ maxWidth: 20, marginRight: 5 }}
              />
              {`+${countries[country]?.callingCode[0] || ''}`}
            </Box>
          }
          height="48px"
        >
          <Dialog open={Boolean(anchorEl)} fullScreen>
            <DialogTitle>
              <Box display={'flex'} alignItems="center">
                <IconButton onClick={handleClose}>
                  <CloseIcon
                    height={20}
                    width={20}
                    style={{
                      color: '#666666',
                    }}
                  />
                </IconButton>
                <CustomTextField
                  id="countryName"
                  fullWidth
                  variant="outlined"
                  placeholder="Enter country name"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{
                            visibility: !isEmpty(searchKey)
                              ? 'visible'
                              : 'hidden',
                          }}
                          onClick={(event) => {
                            const target =
                              document.getElementById('countryName');

                            if (event.target !== target) {
                              target.focus();
                              target.click();
                            }
                            setSearchKey('');
                          }}
                        >
                          <CloseCircle
                            height={20}
                            width={20}
                            style={{
                              color: '#666666',
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              <List>
                {Object.keys(filterData).map((item) => (
                  <ListItem
                    divider
                    button
                    key={item}
                    value={item}
                    selected={item === selectedItems}
                    onClick={(event) => handleListItemClick(event, item)}
                  >
                    <img
                      src={filterData[item].flag}
                      alt={filterData[item].name.common}
                      style={{ maxWidth: 20, marginRight: 5 }}
                    />
                    {filterData[item].name.common}{' '}
                    {`(+${filterData[item]?.callingCode[0] || ''})`}
                  </ListItem>
                ))}
              </List>
              {/* {Object.keys(filterData).map((item) => (
                <MenuItem key={item} value={item} divider>
                  <img
                    src={filterData[item].flag}
                    alt={filterData[item].name.common}
                    style={{ maxWidth: 20, marginRight: 5 }}
                  />
                  {filterData[item].name.common}{' '}
                  {`(+${filterData[item]?.callingCode[0] || ''})`}
                </MenuItem>
              ))} */}
            </DialogContent>
          </Dialog>
        </CustomSelect>
      ) : (
        <CustomTextField
          select
          value={country}
          onChange={onChange}
          SelectProps={{
            IconComponent: ArrowDown,
            renderValue: (value) => (
              <Box width={100} display="flex" alignItems="center">
                <img
                  src={countries[value]?.flag}
                  alt={countries[value]?.name?.common || ''}
                  style={{ maxWidth: 20, marginRight: 5 }}
                />
                {`+${countries[value]?.callingCode[0] || ''}`}
              </Box>
            ),
          }}
          disabled={disabled}
          variant="outlined"
        >
          {Object.keys(countries).map((item) => (
            <MenuItem key={item} value={item}>
              <img
                src={countries[item].flag}
                alt={countries[item].name.common}
                style={{ maxWidth: 20, marginRight: 5 }}
              />
              {countries[item].name.common}
            </MenuItem>
          ))}
        </CustomTextField>
      )}
    </Box>
  );
};

export default CustomCountryCodeInput;
