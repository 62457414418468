import CustomQuickRangeGroup from './CustomGroupChips';
import { CloseIcon } from '@/assets/svg';
import CustomLoadingButton from '@/new-components/CustomLoadingButton';
import {
  Box,
  withStyles,
  InputLabel,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

const CustomInputLabel = withStyles({
  root: {
    color: '#666666',
    marginBottom: 8,
    fontWeight: 600,
  },
})(InputLabel);

const CustomTitle = withStyles({
  root: {
    fontWeight: 600,
    marginBottom: 20,
    color: '#333333',
  },
})(Typography);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16,
    },

    '& .MuiInputBase-inputMultiline': {
      '&.MuiInputBase-input': {
        marginLeft: 0,
      },
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiTypography-root': {
        fontSize: 16,
        color: '#333333',
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
      },
      '& input': {
        marginLeft: 0,
      },
    },
    '& .MuiFormHelperText-root': {
      alignItems: 'center',
      display: 'flex',
      fontFamily: 'OpenSans',
      fontSize: 12,
      gap: 6,
      marginLeft: 2,

      '& .MuiSvgIcon-root': {
        fontSize: 'unset',
      },
    },
  },
})(TextField);

const SymptomsStep = ({
  selectedItems,
  setSelectedItems,
  handleSubmit,
  searchKey,
  setSearchKey,
}) => {
  const { symptoms } = useSelector((state) => state.teleConsultNow);
  const handleChangeFrom = (e, values) => {
    let newSearchKey = values.join(', ');

    let newList = newSearchKey.split(',').filter((it) => it);

    newList = newList
      .concat(newList)
      .map((it) => {
        if (!symptoms.includes(it)) {
          return it.replace(' ', '');
        }
        return it;
      })
      .filter((it) => it);
    newList = newList.filter((item, pos) => {
      return newList.indexOf(item) === pos;
    });

    setSearchKey(newSearchKey);
    setSelectedItems(newList);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height={'100%'}
      px={2.5}
    >
      <Box maxWidth={600} textAlign="left" mt={2}>
        <CustomTitle>Why do you need a doctor?</CustomTitle>
        <CustomInputLabel>Symptoms</CustomInputLabel>

        <CustomTextField
          fullWidth
          variant="outlined"
          placeholder="Sore throat"
          value={searchKey}
          onChange={(e) => {
            const newList = symptoms?.filter((item) =>
              e.target.value.includes(item)
            );
            let holdList = [];

            if (isEmpty(newList)) {
              holdList = e.target.value.split(',').filter((it) => it);
            } else {
              holdList = e.target.value
                ?.split(',')
                .filter((item) => !symptoms.includes(item));
              holdList = holdList
                .concat(newList)
                .map((it) => {
                  if (!symptoms.includes(it)) {
                    return it.replace(' ', '');
                  }
                  return it;
                })
                .filter((it) => it);

              holdList = holdList.filter((item, pos) => {
                return holdList.indexOf(item) === pos;
              });
            }

            setSelectedItems(holdList);
            setSearchKey(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <IconButton
                    style={{
                      visibility: !isEmpty(searchKey) ? 'visible' : 'hidden',
                    }}
                    onClick={() => {
                      setSearchKey('');
                      setSelectedItems([]);
                    }}
                  >
                    <CloseIcon
                      height={20}
                      width={20}
                      style={{
                        color: '#666666',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              </>
            ),
          }}
          style={{ marginBottom: 10 }}
        />

        {symptoms && (
          <>
            <CustomQuickRangeGroup
              value={selectedItems}
              handleChange={handleChangeFrom}
              listButtons={symptoms}
            />
          </>
        )}
      </Box>

      <CustomLoadingButton
        onClick={handleSubmit}
        variant="contained"
        color="primary"
        fullWidth
        disabled={isEmpty(selectedItems)}
        style={{ marginTop: 24, width: '100%' }}
      >
        Call now
      </CustomLoadingButton>
    </Box>
  );
};

export default SymptomsStep;
