import authDispatcher from '../../action/auth';
import AccountInfo from './AccountInfo';
import CompleteProfile from './CompleteProfile';
import VerifyOTP from './VerifyOTP';
import { LogoSmall, NotiEmailIcon } from '@/assets/svg';
import { urlLabel } from '@/enum/PermissionEnum';
import { getNationalNumber } from '@/helpers';
import {
  ALPHA_NUMERIC_REGEX,
  PASSWORD_REGEX,
} from '@/helpers/validationHelpers/validationSchema';
import CustomButton from '@/new-components/CustomButton';
import ModalConfirmationMobile from '@/new-components/CustomModal/ModalConfirmationMobile';
import {
  checkRegexFullName,
  validateEmail,
  validateIdentity,
  validateMobile,
  validateNRIC,
} from '@/utils/validation';
import { Box, IconButton, Typography, withStyles } from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactRecaptcha3 from 'react-google-recaptcha3';
import Progress from 'react-progress-2';
import { Redirect } from 'react-router-dom';

const CustomTitle = withStyles({
  root: {
    fontSize: 18,
    fontFamily: 'Lato',
    fontWeight: 700,
    color: '#191919',
  },
})(Typography);

export const REGISTER_PROCESS_STEPS = {
  ACCOUNT_INFO: 'accountInfo',
  PERSONAL_INFO: 'personalInfo',
  VERIFY_OTP: 'verifyOTP',
};

const PatientRegister = ({ history }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [showNoticeModal, setShowNoticeModal] = useState(false);
  const [showNoticeEmailModal, setShowNoticeEmailModal] = useState(false);
  const [unitNumber, setUnitNumber] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [didSendOTP, setDidSendOTP] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState('');

  const [formValue, setFormValue] = useState({
    email: '',
    countryCode: 'SG',
    phoneNumber: '',
    password: '',
    requestKey: '',
    otp: '',
    fullName: '',
    dateOfBirth: '',
    gender: '',
    identityType: '',
    identityNumber: '',
    ethnicGroup: 'Others',
    notificationEmail: '',
    isMinmedBooking: true,
    loginType: 'Manual',
    socialToken: '',
    postalCode: '',
    unitNumber: '',
    address: '',
    floor: '',
    ignoredUnitNumber: false,
  });

  useEffect(() => {
    ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_TOKEN);
  }, []);

  const handleChangeForm = (key) => (e, newValue) => {
    let value = get(e, 'target.value');
    switch (key) {
      case 'phoneNumber':
        value = e.value;
        break;

      case 'email':
        value = get(e, 'target.value').trim();
        break;

      case 'dateOfBirth':
        value = moment(e).startOf('date').format();
        break;

      case 'gender':
        value = newValue;
        break;

      case 'ignoredUnitNumber':
        value = get(e, 'target.checked');
        break;

      case 'postalCode':
        // Regex labnum number contain only number without dot and comma symbol
        const regexNumber = /^[0-9\b]+$/;
        value = regexNumber.test(value) ? value : '';
        break;

      default:
        break;
    }

    if (key === 'identityType') {
      setFormValue({
        ...formValue,
        [key]: value,
        identityNumber: '',
      });
    } else if (key === 'ignoredUnitNumber') {
      setFormValue({
        ...formValue,
        [key]: value,
        floor: '',
        unitNumber: '',
      });
      setUnitNumber('');
    } else setFormValue({ ...formValue, [key]: value });
  };

  const handleClearForm = (key) => {
    if (key === 'confirmPassword') {
      setConfirmPassword('');
    } else if (key === 'unitNumber') {
      setUnitNumber('');
      setFormValue({
        ...formValue,
        unitNumber: '',
        floor: '',
      });
    } else setFormValue({ ...formValue, [key]: '' });
  };

  const handleCountryCode = (value) => {
    console.log('value', value);
    setFormValue({
      ...formValue,
      countryCode: value,
    });
  };

  const handleChangeUnitNumber = (e) => {
    const value = e.target.value;
    let floor = value.slice(0, 3).replace('#', '') || '';
    let unitNumber = value.slice(3).replace('-', '') || '';

    if (
      !(
        !ALPHA_NUMERIC_REGEX.test(floor) ||
        !ALPHA_NUMERIC_REGEX.test(unitNumber)
      )
    ) {
      setUnitNumber(value);
      setFormValue({
        ...formValue,
        unitNumber,
        floor,
      });
    }
  };

  const goLogin = () => {
    history.push(`/${urlLabel.patientLogin}`);
  };

  const handleNext = () =>
    setCurrentStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () => {
    setCurrentStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validAccountInfo = (data) => {
    let valid = true;
    let errors = {};
    if (!validateMobile(data.phoneNumber, data.countryCode)) {
      errors = { ...errors, phoneNumber: 'Phone number is invalid!' };
      valid = false;
    }

    if (!validateEmail(data.email)) {
      errors = { ...errors, email: 'Email is invalid!' };
      valid = false;
    }

    if (confirmPassword !== data.password) {
      errors = {
        ...errors,
        confirmPassword: 'Your Password and Confirmation password must match.',
      };
      valid = false;
    }
    if (!data.email || !data.password || !data.phoneNumber) {
      errors = {
        ...errors,
        email: 'Email is required!',
        password: 'Password is required!',
        phoneNumber: 'Phone is required!',
      };

      valid = false;
    }
    if (!PASSWORD_REGEX.test(data.password)) {
      errors = {
        ...errors,
        password:
          'Password must be 8-32 characters long and includes at least one number, one letter (uppercase or lowercase), and may contain special characters like !@#$%^&*(),_-+=',
      };

      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const validCompleteProfile = (data) => {
    let valid = true;
    let errors = {};

    if (formValue.identityNumber) {
      const validIdentityNumber =
        formValue.identityType === 'NRIC'
          ? validateNRIC(formValue.identityNumber)
          : validateIdentity(formValue.identityNumber);

      if (!validIdentityNumber) {
        errors = { ...errors, identityNumber: 'Identity Number is invalid!' };
        valid = false;
      }
    }

    if (formValue.fullName) {
      const isValidFullName = checkRegexFullName(formValue.fullName);
      if (!isValidFullName) {
        errors = {
          ...errors,
          fullName:
            'Full Name must contain letters only or letters combined with special characters',
        };
        valid = false;
      }
    }

    setErrors(errors);
    return valid;
  };

  const handleNextAccountStep = () => {
    setShowLoading(true);
    // Check invalid

    if (!validAccountInfo(formValue)) {
      setShowLoading(false);
      return;
    }

    setErrors({});
    authDispatcher.checkEmailExist(
      formValue.email,
      'Patient',
      () => {
        authDispatcher.checkMobileExist(
          getNationalNumber(formValue.phoneNumber, formValue.countryCode),
          formValue.countryCode,
          'Patient',
          () => {
            // getReCaptcha();
            handleNext();
            setShowLoading(false);
          },
          () => setShowLoading(false)
        );
      },
      () => setShowLoading(false)
    );
  };

  const handleNextCompleteProfileStep = () => {
    if (!validCompleteProfile(formValue)) {
      setShowLoading(false);
      return;
    }
    setErrors({});

    handleNext();
  };

  const handleFinalStep = (otp) => {
    // Register
    authDispatcher.registerPatient(
      {
        ...formValue,
        otp,
      },
      (isSuccess) => {
        setShowLoading(false);
        if (isSuccess) {
          setShowNoticeEmailModal(true);
        }
      }
    );
  };

  const sendOTP = (reCaptchaToken, callback) => {
    authDispatcher.checkPatientPreregistersAccount(
      {
        email: formValue.email,
        phoneNumber: formValue.phoneNumber,
        countryCode: formValue.countryCode,
        reCaptchaToken,
      },
      (result) => {
        callback && callback();
        Progress.hide();
        setFormValue({ ...formValue, requestKey: result });
      }
    );
  };

  const getReCaptcha = () => {
    ReactRecaptcha3.getToken().then(
      (resp) => {
        Progress.show();
        setShowLoading(true);
        setDidSendOTP(false);
        sendOTP(resp, () => {
          Progress.hide();
          setDidSendOTP(true);
          setShowLoading(false);
          setReCaptchaToken(resp);
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const reSendOTP = () => {
    Progress.show();
    setDidSendOTP(false);
    getReCaptcha();
    // sendOTP(reCaptchaToken, () => {
    //   Progress.hide();
    //   setDidSendOTP(true);
    // });
  };

  const STEPS = [
    {
      key: REGISTER_PROCESS_STEPS.ACCOUNT_INFO,
      component: (
        <AccountInfo
          loading={showLoading}
          errors={errors}
          formValue={formValue}
          goLogin={goLogin}
          handleChangeForm={handleChangeForm}
          handleSubmit={handleNextAccountStep}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          handleClearForm={handleClearForm}
          handleCountryCode={handleCountryCode}
        />
      ),
    },
    {
      key: REGISTER_PROCESS_STEPS.PERSONAL_INFO,
      name: 'Complete profile',
      component: (
        <CompleteProfile
          loading={showLoading}
          errors={errors}
          formValue={formValue}
          handleChangeForm={handleChangeForm}
          handleSubmit={handleNextCompleteProfileStep}
          unitNumber={unitNumber}
          handleChangeUnitNumber={handleChangeUnitNumber}
          handleClearForm={handleClearForm}
        />
      ),
    },
    {
      key: REGISTER_PROCESS_STEPS.VERIFY_OTP,
      name: 'Create an account',
      component: (
        <VerifyOTP
          getReCaptcha={getReCaptcha}
          didSendOTP={didSendOTP}
          reSendOTP={reSendOTP}
          formValue={formValue}
          handleSubmit={handleFinalStep}
        />
      ),
    },
  ];

  if (
    process.env.REACT_APP_MINC_DOMAIN &&
    window.location.host === process.env.REACT_APP_MINC_DOMAIN &&
    window.location.hash === `#/${urlLabel.patientRegister}`
  ) {
    return <Redirect to={`${urlLabel.register}`} />;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p={2.5}
      height={'100dvh'}
    >
      {[REGISTER_PROCESS_STEPS.ACCOUNT_INFO].includes(
        STEPS[currentStep].key
      ) ? (
        <Box mb={5}>
          <LogoSmall />
        </Box>
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box flex={1}>
              <IconButton onClick={handleBack} disabled={currentStep === 0}>
                <KeyboardArrowLeft />
              </IconButton>
            </Box>
            <Box flex={2} textAlign="center">
              <CustomTitle>{STEPS[currentStep].name}</CustomTitle>
            </Box>
            <Box flex={1}></Box>
          </Box>
        </>
      )}

      {STEPS[currentStep].component}
      {showNoticeModal && (
        <ModalConfirmationMobile
          open={showNoticeModal}
          onClose={() => setShowNoticeModal(false)}
          title={
            <Box
              height={40}
              width="100%"
              textAlign="center"
              position="relative"
            >
              <LogoSmall style={{ position: 'absolute', left: 0 }} />
              <CustomTitle style={{ lineHeight: 2.5 }}>Notice</CustomTitle>
            </Box>
          }
          subContent={
            <Box color="#292929" fontSize={16} px={2.5} mt={8} textAlign="left">
              <p>
                Telemedicine is suitable for non-emergency conditions. There are
                limitations and the consult may be cancelled without charge.
              </p>
              <p>
                Find out more in our{' '}
                <a
                  className="custom-link"
                  target="blank"
                  href="https://minmed.sg/teleconsult-doctor/#faq"
                >
                  FAQs
                </a>
                . Please proceed to the nearest Accident and Emergency facility
                if you need urgent medical attention.
              </p>
            </Box>
          }
        />
      )}

      {showNoticeEmailModal && (
        <ModalConfirmationMobile
          open={showNoticeEmailModal}
          onClose={() => setShowNoticeModal(false)}
          mainContent="Almost there!"
          title={<Box height={40}></Box>}
          subContent={
            <Box color="#666666" fontSize={16} px={7} mt={2} textAlign="center">
              <p>
                Please click on the verification link in your email to activate
                your account.
              </p>
            </Box>
          }
          otherActionsContent={() => (
            <CustomButton
              onClick={goLogin}
              variant="contained"
              color="primary"
              style={{ width: '100%', height: 48 }}
              margin="0 16px"
            >
              Done
            </CustomButton>
          )}
          Icon={<NotiEmailIcon style={{ marginTop: 100 }} />}
        />
      )}
    </Box>
  );
};

export default PatientRegister;
