import GoogleLogo from '../../../../assets/image/google-logo.png';
import { countries } from '../../../../countries';
import { urlLabel } from '../../../../enum/PermissionEnum';
import { doctorStatus } from '../../../../enum/RequestEnum';
import {
  signInWithGoogle,
  setUpRecaptchaThenSendOTP,
  sendOTP,
} from '../../../../firebase';
import { getNumberInfo } from '../../../../helpers';
import { getStorage, setStorage, removeStorage } from '../../../../helpers';
import { StyledButton } from '../../../common/componentUI/commonStyleComponents';
import authDispatcher from '../../action/auth';
import {
  BTN_LOGIN_ID,
  BTN_UPDATE_PROFILE_ID,
  INTERNAL_ROLE,
  LOGIN_PROCESS_STEPS,
} from '../../constants';
import Login2FA from './Login2FA';
import LoginInfo from './LoginInfo';
import LoginOTP from './LoginOTP';
import UpdateInfo1 from './UpdateInfo1';
import UpdateInfo2 from './UpdateInfo2';
import validateData from '@/helpers/validationHelpers/validationSchema';
import customToast from '@/new-components/CustomNotification';
import { Slide } from '@material-ui/core';
import { startsWith, get } from 'lodash';
import React, { useState, useEffect } from 'react';
import Progress from 'react-progress-2';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const Login = ({ history, clinics }) => {
  const [email, setEmail] = useState(
    process.env.NODE_ENV === 'development'
      ? 'admin_sgtech_1@telehealth.com'
      : ''
  );
  const [password, setPassword] = useState(
    process.env.NODE_ENV === 'development' ? '1' : ''
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const [apiErr, setApiErr] = useState(null);
  const [step, setStep] = useState(LOGIN_PROCESS_STEPS.accountInfo);
  const [loginResult, setLoginResult] = useState();
  const [didSendOTP, setDidSendOTP] = useState(false);
  const [info, setInfo] = useState(null);
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('SG');
  const [mcr, setMcr] = useState('');
  const [cerPhotoUrl, setCerPhotoUrl] = useState('');
  const [cerExpDate, setCerExpDate] = useState(new Date());
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [credentials, setCredentials] = useState('');
  const [clinicId, setClinicId] = useState('');
  const [showLoginLoading, setLoginLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    authDispatcher.getAllClinics();
    const isChecked = getStorage('isChecked');
    if (isChecked) {
      setEmail(getStorage('email'));
      setPassword(getStorage('password'));
    }
  }, []);

  const handleLogin = async (remember) => {
    try {
      setLoginLoading(true);
      if (remember) {
        setStorage('email', email);
        setStorage('password', password);
        setStorage('isChecked', true);
      } else {
        removeStorage(['email', 'password', 'isChecked']);
      }

      const correctEmail = email.trim();
      if (!correctEmail || !password) {
        setApiErr('Please enter email & password');
        setShowError(true);
        setLoginLoading(false);
        return;
      }
      await validateData(
        'loginSchema',
        { email: correctEmail, password },
        () => {
          authDispatcher.login(correctEmail, password, (result, email) => {
            if (result.roleType === 'Patient') {
              setLoginLoading(false);
              return customToast('error', 'Permission denied');
            }
            //  else if (result.roleType === 'MHS') {
            //   setLoginLoading(false);
            //   return authDispatcher.loginSuccess(result, result.email);
            // }

            if (!email) {
              setLoginLoading(false);
              setApiErr(result);
              return setShowError(true);
            } else {
              if (!result.mobile) {
                setLoginLoading(false);
                if (INTERNAL_ROLE.includes(result.roleType))
                  return customToast('error', 'Permission denied');
                return customToast(
                  'error',
                  'Cannot receive mobile number info'
                );
              } else if (!result.countryCode) {
                setLoginResult({
                  ...result,
                  email,
                });
                toVerifyOTP({
                  ...result,
                  email,
                });
              } else if (result.accessToken) {
                if (result.isVerifiedGoogleAuthenticator) {
                  // only verify code
                  setLoginResult(result);
                  setStep(LOGIN_PROCESS_STEPS.verify2FA);
                  setLoginLoading(false);
                } else {
                  // First time gen image qr code
                  getAuthenticatorInfo(result);
                }
              }
            }
          });
        }
      );
    } catch (errs) {
      setErrors(errs);
      setApiErr(errs.email);
      setShowError(true);
      setLoginLoading(false);
    }
  };

  const getAuthenticatorInfo = (data) => {
    authDispatcher.getAuthenticatorsInfo(
      data.accessToken,
      ({ imageUrl, manualEntryKey }) => {
        if (imageUrl && manualEntryKey) {
          setLoginResult({ ...data, imageUrl, manualEntryKey });
          setStep(LOGIN_PROCESS_STEPS.verify2FA);
          setLoginLoading(false);
        } else {
          customToast('error', 'Something went wrong');
        }
      }
    );
  };

  const toVerifyOTP = (data) => {
    Progress.show();
    setUpRecaptchaThenSendOTP({
      phone: startsWith(data.mobile, '+') ? data.mobile : `+${data.mobile}`,
      buttonId: BTN_LOGIN_ID,
      ifSuccess: () => {
        Progress.hide();
        setLoginLoading(false);
        setStep(LOGIN_PROCESS_STEPS.verifyOTP);
        setDidSendOTP(true);
      },
      ifFailed: (err) => {
        Progress.hide();
        setLoginLoading(false);
        customToast('error', err.message);
        return;
      },
    });
    // Twilio otp
    // if (
    //   process.env.REACT_APP_SECRET_NAME &&
    //   process.env.REACT_APP_SECRET_NAME === 'localtest'
    // ) {
    //   Progress.hide();
    //   setLoginLoading(false);
    //   setStep(LOGIN_PROCESS_STEPS.verifyOTP);
    //   setDidSendOTP(true);
    // } else
    //   authDispatcher.sendOTP({ otpPhoneNumber: data.mobile }, result => {
    //     if (result) {
    //       Progress.hide();
    //       setLoginLoading(false);
    //       setStep(LOGIN_PROCESS_STEPS.verifyOTP);
    //       setDidSendOTP(true);
    //     }
    //     Progress.hide();
    //     setLoginLoading(false);
    //   });
  };

  const toVerifyOTPWhenUpdating = () => {
    Progress.show();
    setUpRecaptchaThenSendOTP({
      phone: getNumberInfo(phone, countryCode)[1],
      buttonId: BTN_UPDATE_PROFILE_ID,
      ifSuccess: () => {
        Progress.hide();
        setStep(LOGIN_PROCESS_STEPS.updateOTP);
        setDidSendOTP(true);
      },
      ifFailed: (err) => {
        Progress.hide();
        customToast('error', err.message);
        return;
      },
    });

    // Twiliio opt
    // authDispatcher.sendOTP(
    //   { otpPhoneNumber: getNumberInfo(phone, countryCode)[1] },
    //   result => {
    //     if (result) {
    //       Progress.hide();
    //       setStep(LOGIN_PROCESS_STEPS.updateOTP);
    //       setDidSendOTP(true);
    //     }
    //     Progress.hide();
    //   }
    // );
  };

  const reSendOTPWhenUpdating = () => {
    Progress.show();
    setDidSendOTP(false);
    sendOTP({
      phone: `+${countries[countryCode].callingCode[0]}${phone}`,
      ifSuccess: () => {
        Progress.hide();
        customToast('success', 'Resent');
        setDidSendOTP(true);
      },
      ifFailed: (err) => {
        Progress.hide();
        customToast('error', err.message);
        return;
      },
    });

    // Twilio otp
    // authDispatcher.sendOTP(
    //   { otpPhoneNumber: `+${countries[countryCode].callingCode[0]}${phone}` },
    //   result => {
    //     if (result) {
    //       setDidSendOTP(true);
    //       customToast('success','Resent');
    //     }
    //   }
    // );
  };

  const reSendOTP = () => {
    setDidSendOTP(false);
    sendOTP({
      phone: startsWith(loginResult.mobile, '+')
        ? loginResult.mobile
        : `+${loginResult.mobile}`,
      ifSuccess: () => {
        setDidSendOTP(true);
        customToast('success', 'Resent');
      },
      ifFailed: (err) => {
        customToast('error', err.message);
        return;
      },
    });

    // Twilio otp
    // authDispatcher.sendOTP({ otpPhoneNumber: loginResult.mobile }, result => {
    //   if (result) {
    //     Progress.hide();
    //     setDidSendOTP(true);
    //     customToast('success','Resent');
    //   } else {
    //     Progress.hide();
    //     setLoginLoading(false);
    //     return;
    //   }
    // });
  };

  const toRegister = () => {
    history.push(`/${urlLabel.register}`);
  };

  const toForgotPassword = () => {
    history.push(`/${urlLabel.forgotPassword}`);
  };

  const handleGoogleLogin = () => {
    signInWithGoogle({
      loginSuccess: (user, token) => {
        authDispatcher.loginWithGoogle(user, token, (result) => {
          if (!result.mobile) {
            setInfo({
              socialInfo: user,
              token,
              apiResult: result,
            });
            setLastName(user.family_name || '');
            setFirstName(user.given_name || '');
            setStep(LOGIN_PROCESS_STEPS.updateInfo1);
          } else if (result.approveStatus !== doctorStatus.Approved) {
            return customToast('error', 'This account is unapproved');
          } else {
            if (!result.countryCode) {
              setLoginResult({
                ...result,
                email: user.email,
              });
              toVerifyOTP({
                ...result,
                email: user.email,
              });
            } else if (result.accessToken) {
              if (result.isVerifiedGoogleAuthenticator) {
                // only verify code
                setLoginResult(result);
                setStep(LOGIN_PROCESS_STEPS.verify2FA);
                setLoginLoading(false);
              } else {
                // First time gen image qr code
                getAuthenticatorInfo(result);
              }
            }
          }
        });
      },
      loginFailed: (errorMessage) => customToast('error', errorMessage),
    });
  };

  if (
    process.env.REACT_APP_DOXY_DOMAIN &&
    window.location.host === process.env.REACT_APP_DOXY_DOMAIN &&
    window.location.hash === `#/${urlLabel.login}`
  ) {
    return <Redirect to={`${urlLabel.patientLogin}`} />;
  }

  return (
    <>
      <StyledButton
        id={BTN_LOGIN_ID}
        style={{
          display: 'none',
        }}
      >
        Login
      </StyledButton>
      <StyledButton
        id={BTN_UPDATE_PROFILE_ID}
        style={{
          display: 'none',
        }}
      >
        update
      </StyledButton>
      <Slide direction="left" in={step === LOGIN_PROCESS_STEPS.accountInfo}>
        <div
          key={step}
          style={{
            display:
              step === LOGIN_PROCESS_STEPS.accountInfo ? 'static' : 'none',
          }}
        >
          <LoginInfo
            {...{
              apiErr,
              showError,
              setShowError,
              email,
              password,
              showPassword,
              setEmail,
              setShowPassword,
              setPassword,
              handleLogin,
              toForgotPassword,
              toRegister,
              handleGoogleLogin,
              GoogleLogo,
              showLoginLoading,
              errors,
            }}
          />
        </div>
      </Slide>
      <Slide direction="left" in={step === LOGIN_PROCESS_STEPS.verifyOTP}>
        <div
          key={step}
          style={{
            display: step === LOGIN_PROCESS_STEPS.verifyOTP ? 'static' : 'none',
          }}
        >
          <LoginOTP
            loginInfo={loginResult}
            didSendOTP={didSendOTP}
            reSendOTP={reSendOTP}
          />
        </div>
      </Slide>
      <Slide direction="left" in={step === LOGIN_PROCESS_STEPS.updateInfo1}>
        <div
          key={step}
          style={{
            display:
              step === LOGIN_PROCESS_STEPS.updateInfo1 ? 'static' : 'none',
          }}
        >
          <UpdateInfo1
            {...{
              step,
              setStep,
              phone,
              setPhone,
              countryCode,
              setCountryCode,
              mcr,
              setMcr,
              setCerPhotoUrl,
              cerExpDate,
              setCerExpDate,
            }}
          />
        </div>
      </Slide>
      <Slide direction="left" in={step === LOGIN_PROCESS_STEPS.updateInfo2}>
        <div
          key={step}
          style={{
            display:
              step === LOGIN_PROCESS_STEPS.updateInfo2 ? 'static' : 'none',
          }}
        >
          <UpdateInfo2
            {...{
              firstName,
              setFirstName,
              lastName,
              setLastName,
              credentials,
              setCredentials,
              clinicId,
              setClinicId,
              clinics: clinics.list || [],
            }}
            toVerifyOTP={toVerifyOTPWhenUpdating}
          />
        </div>
      </Slide>
      <Slide direction="left" in={step === LOGIN_PROCESS_STEPS.updateOTP}>
        <div
          key={step}
          style={{
            display: step === LOGIN_PROCESS_STEPS.updateOTP ? 'static' : 'none',
          }}
        >
          <LoginOTP
            id={'2'}
            isGoogleLogin
            didSendOTP={didSendOTP}
            phone={phone}
            countryCode={countryCode}
            reSendOTP={reSendOTPWhenUpdating}
            onUpdateInfo={() => {
              authDispatcher.updateDoctorProfile(
                {
                  mobile: phone,
                  countryCode,
                  mcr,
                  praticisingCertificate: cerPhotoUrl,
                  pcExpiry: cerExpDate,
                  firstName,
                  lastName,
                  credentials,
                  clinicId,
                  accessToken: info.apiResult.accessToken,
                  email: info.socialInfo.email,
                },
                history
              );
            }}
          />
        </div>
      </Slide>
      <Slide direction="left" in={step === LOGIN_PROCESS_STEPS.verify2FA}>
        <div
          key={step}
          style={{
            display: step === LOGIN_PROCESS_STEPS.verify2FA ? 'static' : 'none',
          }}
        >
          <Login2FA loginInfo={loginResult} />
        </div>
      </Slide>
    </>
  );
};

const mapStateToProps = (state) => {
  const { clinics } = state.auth;
  return {
    auth: state.auth,
    clinics,
  };
};

export default connect(mapStateToProps)(Login);
