import { ClearComponent } from './ClearComponent';
import ConsultationFeeCard from './ConsultationFeeCard';
import UploadImageContainer from './UploadImageContainer';
import { CheckboxActive, CheckboxDefault } from '@/assets/svg';
import CustomLoadingButton from '@/new-components/CustomLoadingButton';
import {
  Box,
  withStyles,
  InputLabel,
  Typography,
  TextField,
  Divider,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const CustomTitle = withStyles({
  root: {
    fontWeight: 600,
    marginBottom: 8,
    color: '#333333',
  },
})(Typography);

const CustomInputLabel = withStyles({
  root: {
    color: '#666666',
    marginBottom: 8,
    fontWeight: 600,
  },
})(InputLabel);

const CustomDivider = withStyles({
  root: {
    marginBottom: 24,
    marginTop: 24,
  },
})(Divider);

const CustomToggleButtonGroup = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderLeft: '1px solid #E1E1E1',
      borderRadius: 8,
    },
    '&:first-child': {
      borderRadius: 8,
    },
  },
}))(ToggleButtonGroup);

const CustomToggleButton = withStyles((theme) => ({
  root: {
    flex: 1,
    border: '1px solid #E1E1E1',
    color: '#3333333',
    textTransform: 'unset',
    fontSize: 16,
    margin: 0,

    '&.Mui-selected': {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.light,

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    '&.error': {
      border: `1px solid ${theme.palette.feedback.error}`,
    },
  },
}))(ToggleButton);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16,
    },

    '& .MuiInputBase-inputMultiline': {
      '&.MuiInputBase-input': {
        marginLeft: 0,
      },
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiTypography-root': {
        fontSize: 16,
        color: '#333333',
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
      },
      '& input': {
        marginLeft: 0,
      },
    },
    '& .MuiFormHelperText-root': {
      alignItems: 'center',
      display: 'flex',
      fontFamily: 'OpenSans',
      fontSize: 12,
      gap: 6,
      marginLeft: 2,

      '& .MuiSvgIcon-root': {
        fontSize: 'unset',
      },
    },
  },
})(TextField);

const CorporatePlanStep = ({
  errors,
  loading,
  valueOption,
  formValue,
  setFormValue,
  handleChangeForm,
  handleSubmit,
  handleChangeValueOption,
  valueCorporate,
  setValueCorporate,
  handleClearForm,
}) => {
  const handleChangeCorporate = (corItem) => (e) => {
    if (e.target.value === valueCorporate) {
      setValueCorporate('');
      setFormValue({
        ...formValue,
        corperatePlan: '',
        frontCorperatePlan: '',
        backCorperatePlan: '',
      });
    } else {
      setValueCorporate(e.target.value);
      if (corItem !== 'new')
        setFormValue({
          ...formValue,
          corperatePlan: corItem.name,
          frontCorperatePlan: corItem.frontPhoto,
          backCorperatePlan: corItem.backPhoto,
        });
      else {
        setFormValue({
          ...formValue,
          corperatePlan: '',
          frontCorperatePlan: '',
          backCorperatePlan: '',
        });
      }
    }
  };

  const { corporatePlans } = useSelector((state) => state.teleConsultNow);

  const isEmptyParams = useMemo(() => {
    const filterKeys = [
      'corperatePlan',
      'frontCorperatePlan',
      'backCorperatePlan',
    ];

    let checkValid = false;
    if (valueOption === 'yes') {
      if (valueCorporate === 'new')
        checkValid = filterKeys.some((key) => !formValue[key]);
      else checkValid = isEmpty(formValue.corperatePlan);
    }

    return isEmpty(valueOption) || checkValid;
  }, [formValue, valueOption, valueCorporate]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      overflow="hidden"
    >
      <Box
        maxWidth={600}
        textAlign="left"
        width="100%"
        mt={2.5}
        mb={2.5}
        className="container-scroll"
        height="calc(160vh - 200px)"
        px={2.5}
      >
        <CustomTitle>Utilize your corporate plan?</CustomTitle>

        <CustomToggleButtonGroup
          exclusive
          value={valueOption}
          onChange={handleChangeValueOption}
        >
          <CustomToggleButton value={'no'}>No</CustomToggleButton>
          <CustomToggleButton value={'yes'}>Yes</CustomToggleButton>
        </CustomToggleButtonGroup>

        {valueOption ? (
          <Box>
            <ConsultationFeeCard valueOption={valueOption} />
            <CustomInputLabel style={{ marginTop: 24 }}>
              Promo code
            </CustomInputLabel>
            <CustomTextField
              id="promotionCode"
              fullWidth
              variant="outlined"
              placeholder="Enter code"
              value={formValue.promotionCode || ''}
              onChange={handleChangeForm('promotionCode')}
              error={!!errors?.promotionCode}
              helperText={errors?.promotionCode}
              InputProps={{
                endAdornment: (
                  <ClearComponent
                    id="promotionCode"
                    value={formValue.promotionCode}
                    onClear={() => handleClearForm('promotionCode')}
                  />
                ),
              }}
            />

            {valueOption === 'yes' ? (
              <>
                <CustomDivider />
                {isEmpty(corporatePlans) ? (
                  <>
                    <CustomTitle>Fill in your corporate plan</CustomTitle>

                    <CustomInputLabel style={{ marginTop: 24 }}>
                      Corporate plan
                    </CustomInputLabel>
                    <CustomTextField
                      id="corperatePlan"
                      fullWidth
                      variant="outlined"
                      placeholder="Enter code"
                      value={formValue.corperatePlan || ''}
                      onChange={handleChangeForm('corperatePlan')}
                      InputProps={{
                        endAdornment: (
                          <ClearComponent
                            id="corperatePlan"
                            value={formValue.corperatePlan}
                            onClear={() => handleClearForm('corperatePlan')}
                          />
                        ),
                      }}
                    />
                    <CustomInputLabel style={{ marginTop: 24 }}>
                      Corporate plan card images
                    </CustomInputLabel>
                    <UploadImageContainer
                      keyName="frontCorperatePlan"
                      label="Front of card"
                      imageUrl={formValue.frontCorperatePlan}
                      handleChangeForm={handleChangeForm}
                      formValue={formValue}
                      setFormValue={setFormValue}
                    />
                    <UploadImageContainer
                      keyName="backCorperatePlan"
                      label="Back of card"
                      imageUrl={formValue.backCorperatePlan}
                      handleChangeForm={handleChangeForm}
                      formValue={formValue}
                      setFormValue={setFormValue}
                    />
                  </>
                ) : (
                  <>
                    <CustomTitle>Select your corporate plan</CustomTitle>
                    <Box display="flex" flexDirection="column">
                      <RadioGroup value={valueCorporate}>
                        {corporatePlans.map((cor) => {
                          return (
                            <FormControlLabel
                              onClick={handleChangeCorporate(cor)}
                              key={cor.id}
                              value={cor.name}
                              control={
                                <Radio
                                  style={{ marginLeft: 3 }}
                                  icon={<CheckboxDefault />}
                                  checkedIcon={<CheckboxActive />}
                                  color="primary"
                                />
                              }
                              label={cor.name}
                            />
                          );
                        })}
                        <FormControlLabel
                          onClick={handleChangeCorporate('new')}
                          value="new"
                          control={
                            <Radio
                              style={{ marginLeft: 3 }}
                              icon={<CheckboxDefault />}
                              checkedIcon={<CheckboxActive />}
                              color="primary"
                            />
                          }
                          label="New corporate plan"
                        />
                      </RadioGroup>
                    </Box>
                  </>
                )}
                {valueCorporate === 'new' ? (
                  <>
                    <CustomInputLabel style={{ marginTop: 24 }}>
                      Corporate plan
                    </CustomInputLabel>
                    <CustomTextField
                      id="corperatePlan2"
                      fullWidth
                      variant="outlined"
                      placeholder="Enter code"
                      value={formValue.corperatePlan || ''}
                      onChange={handleChangeForm('corperatePlan')}
                      InputProps={{
                        endAdornment: (
                          <ClearComponent
                            id="corperatePlan2"
                            value={formValue.corperatePlan}
                            onClear={() => handleClearForm('corperatePlan')}
                          />
                        ),
                      }}
                    />
                    <CustomInputLabel style={{ marginTop: 24 }}>
                      Corporate plan card images
                    </CustomInputLabel>
                    <UploadImageContainer
                      keyName="frontCorperatePlan"
                      label="Front of card"
                      imageUrl={formValue.frontCorperatePlan}
                      handleChangeForm={handleChangeForm}
                      formValue={formValue}
                      setFormValue={setFormValue}
                    />
                    <UploadImageContainer
                      keyName="backCorperatePlan"
                      label="Back of card"
                      imageUrl={formValue.backCorperatePlan}
                      handleChangeForm={handleChangeForm}
                      formValue={formValue}
                      setFormValue={setFormValue}
                    />
                  </>
                ) : null}
              </>
            ) : null}
          </Box>
        ) : null}
      </Box>
      <Box width={'100%'} px={2.5}>
        <CustomLoadingButton
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          fullWidth
          loading={loading}
          disabled={isEmptyParams || loading}
          style={{ width: '100%' }}
        >
          Call now
        </CustomLoadingButton>
      </Box>
    </Box>
  );
};

export default CorporatePlanStep;
