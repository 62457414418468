import minkConfig from '../../mink.config.json';
import waitingRoomDispatcher from './action/waitingRoom';
import customToast from '@/new-components/CustomNotification';
import { Box, Button, Paper, Typography, makeStyles } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: 5,
    borderRadius: 20,
    fontWeight: 600,
    textTransform: 'unset',
    flex: 1,
    minWidth: 160,
    boxShadow: 'none',
  },
}));

const RejoinCallPaper = ({
  rejoinCallData,
  onJoin,
  isReconnectRejoinCall,
  onCancel,
}) => {
  const classes = useStyles();
  useEffect(() => {
    if (isReconnectRejoinCall) onJoin(rejoinCallData);
  }, [isReconnectRejoinCall]);

  return (
    <Paper
      style={{
        padding: 20,
        paddingTop: 10,
        paddingBottom: 10,
        border: '1px solid #AD5E99',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 20,
      }}
    >
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <ErrorOutline style={{ marginRight: 10 }} />
        <Typography style={{ marginRight: 8 }}>
          {`Your call with ${rejoinCallData.PatientFullName} was disconnected. Would you like to rejoin?`}
        </Typography>
        <Timer
          time={new moment(rejoinCallData.CallProblemTime).unix()}
          rejoinCallData={rejoinCallData}
        />
      </div>
      <Box display="flex" gridGap={10}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={onCancel}
        >
          Cancel call
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() => onJoin(rejoinCallData)}
        >
          Resume call
        </Button>
      </Box>
    </Paper>
  );
};

const Timer = ({ time, rejoinCallData }) => {
  const [duration, setDuration] = useState(
    isNaN(time)
      ? 300
      : time + minkConfig.DOCTOR_REJOIN_TIMEOUT - new moment().unix()
  );
  useEffect(() => {
    const interval = setInterval(() => {
      if (duration <= 0) {
        customToast('info', `Your consult is expired`);
        waitingRoomDispatcher.setState('rejoinCallData', null);
        waitingRoomDispatcher.setState('callId', rejoinCallData.CallId);
        waitingRoomDispatcher.setState('consConfirmVisibility', true);
        clearInterval(interval);
      }
      setDuration((duration) => duration - 1);
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  return (
    <Typography style={{ color: '#8F9BA9' }}>
      {`Call automatically ends in ${moment
        .utc(moment.duration(duration, 'seconds').asMilliseconds())
        .format('mm:ss')}`}
    </Typography>
  );
};

export default RejoinCallPaper;
