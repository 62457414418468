import authDispatcher from '../../action/auth';
import {
  CloseCircle,
  HideIcon,
  LockFilledIcon,
  LogoSmall,
  UnHideIcon,
} from '@/assets/svg';
import { urlLabel } from '@/enum/PermissionEnum';
import validateData from '@/helpers/validationHelpers/validationSchema';
import { ENTER_KEY } from '@/module/common';
import CustomButton from '@/new-components/CustomButton';
import CustomLoadingButton from '@/new-components/CustomLoadingButton';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import ModalConfirmationMobile from '@/new-components/CustomModal/ModalConfirmationMobile';
import customToast from '@/new-components/CustomNotification';
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

const CustomLink = withStyles({
  root: {
    fontWeight: 600,
    color: '#AD5E99',
    cursor: 'pointer',
  },
})(Typography);

const CustomTypography = withStyles({
  root: {
    color: '#666666',
  },
})(Typography);

const CustomLabel = withStyles({
  root: {
    fontSize: 18,
    fontFamily: 'Lato',
    fontWeight: 700,
    color: '#191919',
    padding: '16px 0px',
  },
})(Typography);

const CustomInputLabel = withStyles({
  root: {
    color: '#666666',
    marginBottom: 8,
    fontWeight: 600,
  },
})(InputLabel);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16,
    },

    '& .MuiInputBase-inputMultiline': {
      '&.MuiInputBase-input': {
        marginLeft: 0,
      },
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiTypography-root': {
        fontSize: 16,
        color: '#333333',
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
      },
      '& input': {
        marginLeft: 0,
      },
    },
    '& .MuiFormHelperText-root': {
      alignItems: 'center',
      display: 'flex',
      fontFamily: 'OpenSans',
      fontSize: 12,
      gap: 6,
      marginLeft: 2,

      '& .MuiSvgIcon-root': {
        fontSize: 'unset',
      },
    },
  },
})(TextField);

const CustomDialogContent = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    textAlign: 'center',
    padding: theme.spacing(3),
  },
}))(DialogContent);

const CustomTitle = withStyles((theme) => ({
  root: {
    fontSize: 32,
    fontFamily: 'Lato',
    fontWeight: 700,
    color: '#191919',
  },
}))(Typography);

const PatientLogin = ({ history }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showLoginLoading, setLoginLoading] = useState(false);
  const [showNoticeModal, setShowNoticeModal] = useState(false);
  const [showWrongPassModal, setShowWrongPassModal] = useState(false);
  const [showVerifyActiveModal, setVerifyActiveModal] = useState(false);
  const [firstSend, setFirstSend] = useState(true);

  const [isAccountBlocked, setIsAccountBlocked] = useState(false);

  const [loginInfo, setLoginInfo] = useState(false);

  const [errors, setErrors] = useState({});

  const [formValue, setFormValue] = useState({
    email: '',
    password: '',
  });

  const handleChangeForm = (key) => (e) => {
    setFormValue({ ...formValue, [key]: get(e, 'target.value') });
  };

  const handleLogin = async () => {
    const { email, password } = formValue;

    try {
      setLoginLoading(true);
      const correctEmail = email.trim();

      await validateData(
        'loginSchema',
        { email: correctEmail, password },
        () => {
          setErrors({});

          authDispatcher.loginPatient(correctEmail, password, (result) => {
            if (result.isSuccess) {
              setLoginLoading(false);
              setLoginInfo(result);
              setShowNoticeModal(true);
              setIsAccountBlocked(false);
            } else {
              setLoginLoading(false);
              setLoginInfo(result);

              if (typeof result === 'string') {
                if (result.includes('Invalid email or password')) {
                  customToast(
                    'error',
                    'You have entered an incorrect email or password.'
                  );
                } else if (
                  result.includes('Verify email to active account') ||
                  result.includes('Check your email and verify account')
                ) {
                  setVerifyActiveModal(true);
                } else {
                  customToast('error', result);
                }
              } else {
                if (result.failedLoginAttempts === 5) {
                  setIsAccountBlocked(true);
                  setShowWrongPassModal(true);
                } else {
                  setIsAccountBlocked(false);
                  if (result.failedLoginAttempts === 1) {
                    customToast(
                      'error',
                      'You have entered an incorrect email or password.'
                    );
                  } else {
                    setShowWrongPassModal(true);
                  }
                }
              }
            }
          });
        }
      );
    } catch (errs) {
      setErrors(errs);
      setLoginLoading(false);
    }
  };

  const goForgotPassword = () => {
    history.push(`/${urlLabel.patientForgotPassword}`);
  };
  const goRegister = () => {
    history.push(`/${urlLabel.patientRegister}`);
  };

  // https://wa.me/6592300123
  const getOutputCount = (input) => {
    const mappingCount = new Map([
      [2, 3],
      [3, 2],
      [4, 1],
    ]);
    return mappingCount.get(input) || null;
  };

  const sendVerifyEmail = () => {
    const time = localStorage.getItem('lastSendVerifyTime');
    const timeEnd = moment(time).add(5, 'minutes');
    const minutes = moment(timeEnd).diff(moment(), 'minutes');

    if (firstSend) {
      authDispatcher.sendEmailActive(formValue.email, (result) => {
        localStorage.setItem('lastSendVerifyTime', moment());
        setFirstSend(false);
        customToast(
          'error',
          'We have just sent you an email. Please check your mail box.'
        );
      });
    } else {
      if (time) {
        // let minutes = 0;
        // minutes = moment(timeEnd).diff(moment(), 'minutes');
        if (minutes > 0 && minutes < 5) {
          customToast(
            'error',
            `You need to wait ${minutes} minute${
              minutes === 1 ? '' : 's'
            } to resend new verify email.`
          );
        } else {
          authDispatcher.sendEmailActive(formValue.email, (result) => {
            localStorage.setItem('lastSendVerifyTime', moment());
            customToast(
              'error',
              'We have just sent you an email. Please check your mail box.'
            );
          });
        }
      }
    }

    setVerifyActiveModal(false);
  };

  if (
    process.env.REACT_APP_MINC_DOMAIN &&
    window.location.host === process.env.REACT_APP_MINC_DOMAIN &&
    window.location.hash === `#/${urlLabel.patientLogin}`
  ) {
    return <Redirect to={`${urlLabel.login}`} />;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p={2.5}
      height={'100dvh'}
    >
      <Box>
        <Box mb={5}>
          <LogoSmall />
        </Box>

        <Box textAlign="center">
          <CustomTitle>Sign in</CustomTitle>

          <form>
            <Box textAlign="left" mt={5}>
              <CustomInputLabel>Email</CustomInputLabel>
              <CustomTextField
                id="email"
                fullWidth
                variant="outlined"
                placeholder="email@address.com"
                autoComplete="email"
                value={formValue.email || ''}
                onChange={handleChangeForm('email')}
                error={!!errors.email}
                helperText={errors.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{
                          visibility: !isEmpty(formValue.email)
                            ? 'visible'
                            : 'hidden',
                        }}
                        onClick={(event) => {
                          const target = document.getElementById('email');

                          if (event.target !== target) {
                            target.focus();
                            target.click();
                          }
                          setFormValue({ ...formValue, email: '' });
                        }}
                      >
                        <CloseCircle
                          height={20}
                          width={20}
                          style={{
                            color: '#666666',
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <CustomInputLabel style={{ marginTop: 24 }}>
                Password
              </CustomInputLabel>
              <CustomTextField
                id="password"
                fullWidth
                variant="outlined"
                placeholder="12345678a"
                autoComplete="password"
                value={formValue.password || ''}
                onChange={handleChangeForm('password')}
                type={showPassword ? '' : 'password'}
                error={!!errors.password}
                helperText={errors.password}
                onKeyDown={(e) => e.keyCode === ENTER_KEY && handleLogin()}
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <IconButton
                          style={{
                            visibility: !isEmpty(formValue.password)
                              ? 'visible'
                              : 'hidden',
                          }}
                          onClick={(event) => {
                            const target = document.getElementById('password');

                            if (event.target !== target) {
                              target.focus();
                              target.click();
                            }
                            setFormValue({ ...formValue, password: '' });
                          }}
                        >
                          <CloseCircle
                            height={20}
                            width={20}
                            style={{
                              color: '#666666',
                            }}
                          />
                        </IconButton>
                      </InputAdornment>

                      <InputAdornment position="start">
                        <>
                          <HideIcon
                            style={{ cursor: 'pointer' }}
                            hidden={showPassword}
                            onClick={() => setShowPassword(true)}
                          />
                          <UnHideIcon
                            style={{ cursor: 'pointer' }}
                            hidden={!showPassword}
                            onClick={() => setShowPassword(false)}
                          />
                        </>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            </Box>

            <CustomLoadingButton
              onClick={handleLogin}
              variant="contained"
              color="primary"
              fullWidth
              disabled={
                showLoginLoading ||
                isEmpty(formValue.email) ||
                isEmpty(formValue.password)
              }
              loading={showLoginLoading}
              style={{ marginTop: 24, width: '100%' }}
            >
              Sign in
            </CustomLoadingButton>

            <CustomLink style={{ marginTop: 24 }} onClick={goForgotPassword}>
              Forgot your password?
            </CustomLink>
          </form>
        </Box>
      </Box>
      <Box display="flex" gridGap={6} alignSelf="center" py={3}>
        <CustomTypography>Don't have an account?</CustomTypography>
        <CustomLink onClick={goRegister}>Sign up</CustomLink>
      </Box>
      {showNoticeModal && (
        <ModalConfirmationMobile
          open={showNoticeModal}
          onClose={() => setShowNoticeModal(false)}
          onClick={() => {
            authDispatcher.loginSuccess(loginInfo, formValue.email);
            setShowNoticeModal(false);
            setLoginLoading(false);
            history.push(`/${urlLabel.teleconsultNow}`);
          }}
          title={
            <Box
              height={40}
              width="100%"
              position="relative"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <LogoSmall style={{ position: 'absolute', left: 0 }} />
              <CustomTitle style={{ fontSize: 18 }}>Notice</CustomTitle>
            </Box>
          }
          subContent={
            <Box color="#292929" fontSize={16} px={2.5} mt={8} textAlign="left">
              <p>
                Telemedicine is suitable for non-emergency conditions. There are
                limitations and the consult may be cancelled without charge.
              </p>
              <p>
                Find out more in our{' '}
                <a
                  className="custom-link"
                  target="blank"
                  href="https://minmed.sg/teleconsult-doctor/#faq"
                >
                  FAQs
                </a>
                . Please proceed to the nearest Accident and Emergency facility
                if you need urgent medical attention.
              </p>
            </Box>
          }
        />
      )}
      {showVerifyActiveModal && (
        <Dialog
          maxWidth="sm"
          open={showVerifyActiveModal}
          onClose={() => setVerifyActiveModal(false)}
          disableBackdropClick
        >
          <CustomDialogContent>
            <CustomLabel>Verify email to activate account</CustomLabel>
            <CustomButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={sendVerifyEmail}
              style={{ width: '100%', borderRadius: 50 }}
            >
              Resend email
            </CustomButton>
            <CustomButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => setVerifyActiveModal(false)}
              style={{ width: '100%', borderRadius: 50 }}
            >
              Ok
            </CustomButton>
          </CustomDialogContent>
        </Dialog>
      )}
      {showWrongPassModal && (
        <ModalConfirmation
          open={showWrongPassModal}
          onClick={() => {
            if (isAccountBlocked) {
              window.open('https://wa.me/6592300123', '_blank');
            } else goForgotPassword();
          }}
          onClose={() => setShowWrongPassModal(false)}
          mainContent={
            isAccountBlocked
              ? 'Account locked'
              : `${getOutputCount(loginInfo?.failedLoginAttempts)} attempt${
                  getOutputCount(loginInfo?.failedLoginAttempts) === 1
                    ? ''
                    : 's'
                } left`
          }
          subContent={
            isAccountBlocked
              ? 'Your account is locked. Please contact us via WhatsApp to resolve the issue.'
              : 'Your email and/or password do not match our records. Please try again or reset your password.'
          }
          cancelLabel="Cancel"
          confirmLabel={isAccountBlocked ? 'Contact us' : 'Reset now'}
          Icon={<LockFilledIcon />}
          style={{ paddingLeft: 0, paddingRight: 0 }}
          btnWidth={140}
        />
      )}
    </Box>
  );
};

export default PatientLogin;
