import {
  Camera2Icon,
  ChatboxIcon,
  Image2Icon,
  SendMessage,
} from '@/assets/svg';
import {
  sendMessage,
  getMessageHistory,
  formatDataMessage,
  receiveMessage,
  uploadToFirebase,
  createChannel,
} from '@/firebase';
import { PreSendImage } from '@/module/chat/PreSendFiles';
import { ENTER_KEY } from '@/module/common';
import customToast from '@/new-components/CustomNotification';
import {
  Box,
  Fab,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { includes, isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { MessageList } from 'react-chat-elements';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    border: '1px solid #f1f1f1',
  },
  title: {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: 18,
    color: '#333',
  },
  closeButton: {
    position: 'absolute',
    left: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  btn: {
    minWidth: 48,
    height: 48,
    backgroundColor: 'white',
    border: '1px solid #ad5e99',
    borderRadius: 8,
    boxShadow: 'none',
  },
  btnSelected: {
    backgroundColor: '#ad5e99',
  },
});

const CustomTextField = withStyles({
  root: {
    fontFamily: 'OpenSans',

    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: 8,
      fontSize: '16px',
    },
    '& input': {
      padding: 8,
      paddingLeft: 16,
      marginLeft: 0,
    },
    '& .Mui-focused > svg': {
      color: '#ad5e99',
    },
  },
})(TextField);

const CustomModalTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, styleButton, ...other } = props;
  return (
    <Box className={classes.root} {...other}>
      <Box
        px={2.5}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box flex={1}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            style={styleButton}
          >
            <KeyboardArrowLeft />
          </IconButton>
        </Box>
        <Box flex={2} textAlign="center">
          <Typography variant="h6" className={classes.title}>
            {children}
          </Typography>
        </Box>
        <Box flex={1}></Box>
      </Box>
    </Box>
  );
});

const StreamMobileChat = ({
  callData,
  isOpen,
  channelInfo,
  onClose,
  setHasNewMessage,
  setChatChannel,
  updateListChannels,
}) => {
  const classes = useStyles();

  const [messages, setMessages] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [chatMessage, setChatMessage] = useState('');

  const messagesRef = useRef();
  messagesRef.current = messages;
  const callId = useSelector((state) => state.teleConsultNow.callId);

  const partner = useSelector((state) => state.teleConsultNow.partner);
  const [isListenNewMess, setIsListen] = useState(false);
  const onReceiveNewMess = (data) => {
    if (!isOpen && includes(channelInfo.members, data.senderId)) {
      setHasNewMessage(true);
    }

    let newMess = formatDataMessage(data);
    setMessages([newMess, ...messagesRef.current]);
    scrollToBottom();
  };

  const getMessage = async () => {
    if (!isEmpty(channelInfo)) {
      receiveMessage(channelInfo.id, onReceiveNewMess);
      let rs = await getMessageHistory(
        channelInfo.id,
        formatDataMessage,
        callId,
        callData.StartCallTime
      );
      setMessages([...rs]);
      scrollToBottom();
    }
  };

  const getReverseMessageList = () => {
    const newMessages = [...messages];
    return newMessages.reverse();
  };

  const scrollToBottom = () => {
    const child = document.getElementById('container');
    const chatContainer = child ? child.parentElement : null;
    if (!chatContainer) return;
    chatContainer.scrollTop = chatContainer.scrollHeight;
  };

  useEffect(() => {
    if (channelInfo?.id && !isListenNewMess) {
      getMessage();
      setIsListen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelInfo]);

  const onClickAddImageIcon = (key) => {
    document.getElementById(key).value = null;
    document.getElementById(key).click();
  };

  const onUploadImage = (key) => {
    const image = document.getElementById(key).files[0];
    if (!image) return;
    if (!['jpeg', 'jpg', 'png'].includes(image.type.split('/')[1]))
      return customToast('error', 'Invalid support file');

    let fileSource;
    const fileReader = new FileReader();
    fileReader.onload = () => {
      fileSource = fileReader.result;
      setUploadedFiles([
        ...uploadedFiles,
        {
          file: image,
          type: 'photo',
          fileSource,
        },
      ]);
    };
    fileReader.readAsDataURL(image);
  };

  const removeAttatchMent = (index) => {
    const newArray = [...uploadedFiles];
    newArray.splice(index, 1);
    setUploadedFiles(newArray);
  };

  const handleSendMessage = () => {
    if (chatMessage.trim() || uploadedFiles.length !== 0) {
      onSend(chatMessage);
    }
    setHasNewMessage(false);
    setChatMessage('');
    setUploadedFiles([]);
  };

  const onSend = async (message) => {
    uploadedFiles.forEach((file) => {
      uploadToFirebase({
        id: channelInfo.id,
        uploadedFile: file,
        callBack: async (downloadUrl) => {
          let info = channelInfo;
          if (isEmpty(info))
            info = await createChannel(
              partner.id,
              partner.name,
              partner.imageUrl
            );
          updateListChannels();
          setChatChannel(info);
          sendMessage(
            info.id,
            file.type === 'photo' ? null : file.file.name,
            info.members,
            {
              fileName: file.file.name,
              type: file.type,
              fileUrl: file.type === 'file' ? downloadUrl : '',
              imageUrl: file.type === 'photo' ? downloadUrl : '',
              mime: file.file.type,
            },
            callId
          );
        },
      });
    });
    if (message.trim() !== '') {
      let info = channelInfo;
      if (isEmpty(info))
        info = await createChannel(partner.id, partner.name, partner.imageUrl);
      updateListChannels();
      setChatChannel(info);
      sendMessage(info.id, message, info.members, undefined, callId);
    }
    scrollToBottom();
  };

  return (
    <ChatContainer id="container">
      <CustomModalTitle
        onClose={onClose}
        style={{ padding: '20px 20px 16px' }}
        styleButton={{ top: 20 }}
      >
        In-call messages
      </CustomModalTitle>
      <MessageList
        className="message-list custom-message-list"
        toBottomHeight={'100%'}
        downButtonBadge={true}
        dataSource={getReverseMessageList()}
        onClick={(item) => {
          if (item.data.uri) window.open(item.data.uri);
        }}
      />
      {!isEmpty(messages) ? null : (
        <Box
          p={2}
          top={100}
          position="absolute"
          bgcolor={'#E2F2FF'}
          color={'#3776AB'}
          display="flex"
          gridGap={12}
          borderRadius={8}
          fontFamily="OpenSans"
          alignItems="center"
          width={'90%'}
          left={'50%'}
          style={{ transform: 'translate(-50%)' }}
        >
          <ChatboxIcon />
          Start the conversation with your doctor by sending a message!
        </Box>
      )}

      <Box
        position="sticky"
        bottom={0}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        borderTop={'1px #f1f1f1 solid'}
      >
        <Box
          display="flex"
          flex={1}
          alignItems={'center'}
          backgroundColor="#ffffff"
          overflow="auto"
          px={2.5}
          py={1.5}
          // height={uploadedFiles.length > 0 ? 150 : 65}
          hidden={uploadedFiles.length === 0}
        >
          {uploadedFiles.map((file, index) => {
            switch (file.type) {
              case 'photo':
                return (
                  <PreSendImage
                    key={index}
                    image={file.file}
                    fileSource={file.fileSource}
                    removeAttatchment={() => removeAttatchMent(index)}
                  />
                );
              //   case 'file':
              //     return (
              //       <PreSendDoc
              //         key={index}
              //         doc={file.file}
              //         removeAttatchment={() => removeAttatchMent(index)}
              //       />
              //     );
              default:
                return null;
            }
          })}
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems={'center'}
          backgroundColor="#ffffff"
          borderTop={'1px #f1f1f1 solid'}
          p="17.5px 19.5px"
          gridGap={8}
        >
          <Fab
            className={classes.btn}
            aria-label="enable-video"
            onClick={() => onClickAddImageIcon('add_picture')}
          >
            <Camera2Icon style={{ color: '#ad5e99' }} />
          </Fab>
          <input
            hidden
            type="file"
            id="add_picture"
            accept="image/*"
            capture="environment"
            onChange={() => onUploadImage('add_picture')}
          />

          <Fab
            className={classes.btn}
            aria-label="enable-video"
            onClick={() => onClickAddImageIcon('add_image')}
          >
            <Image2Icon color="#ad5e99" />
          </Fab>
          <input
            id="add_image"
            type="file"
            accept=".jpeg,.jpg,.png"
            hidden
            onChange={() => onUploadImage('add_image')}
          />

          <CustomTextField
            placeholder="Type something..."
            multiline={false}
            autoFocus={true}
            disableUnderline={true}
            variant="outlined"
            value={chatMessage}
            fullWidth
            onChange={(e) => setChatMessage(e.target.value)}
            onKeyPress={(e) => {
              // Press Shift with Enter
              if (e.shiftKey && e.charCode === ENTER_KEY) {
                return true;
              }
              if (e.charCode === ENTER_KEY) {
                handleSendMessage();
              }
            }}
            InputProps={{
              endAdornment: (
                <SendMessage
                  width={24}
                  height={24}
                  color="#666666"
                  style={{ cursor: 'pointer' }}
                  onClick={handleSendMessage}
                />
              ),
            }}
          />
        </Box>
      </Box>
    </ChatContainer>
  );
};

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100dvh;
  background-color: #ffffff;
  position: relative;

  .message-list {
    flex: 1;
    overflow: auto;
  }

  .input-message {
    border-radius: 20px;
    padding: 0px 16px !important;
    background-color: #f6f7fa;
  }

  .rce-mlist > div:first-of-type {
    padding-top: 10px;
  }

  .rce-container-mbox {
    background-color: #ffffff;
  }

  .rce-container-input {
    min-width: 0%;
  }

  .rce-input {
    background-color: #f6f7fa;
    border-radius: 20px;
    height: 40px;
    width: 100%;
  }

  .rce-mbox-left-notch {
    display: none;
  }

  .rce-mbox-right-notch {
    display: none;
  }

  .rce-mbox-time {
    text-align: right;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    position: initial;
    display: none;
  }

  .rce-mbox-text {
    font-size: 14px;
    word-break: break-word;
    &:after {
      display: none;
    }
  }

  .rce-mbox-body {
    display: flex;
    flex-direction: column;
    padding: 16px;
  }

  .rce-mbox {
    max-width: 60%;
    min-width: 0px;
    border-radius: 8px 8px 8px 2px !important;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    padding: 0px !important;
    box-shadow: none;
    background-color: #f1f1f1;
  }

  .rce-mbox-right {
    background-color: #ffe9fb;
    color: #333333;

    &.rce-mbox {
      border-radius: 8px 2px 8px 8px !important;
    }

    .rce-mbox-file > button {
      background-color: #efdfeb !important;
      border-radius: 8px;
      max-width: -webkit-fill-available;
      max-width: -moz-available;

      svg {
        color: white !important;
      }
    }

    .rce-mbox-file--text {
      padding: 0;
      color: #333333;
    }
    .rce-mbox-time {
      display: none;
      color: #333333;
    }
  }

  .rce-mbox-photo {
    border-radius: 20px;

    &:hover {
      background-color: black !important;
    }
  }

  .rce-mbox-photo--img {
    object-fit: cover;
    height: 150px;
    width: 150px;
    border-radius: 20px !important;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  .rce-mbox--clear-padding {
    // background-color: #f7f7f7 !important;
    box-shadow: none !important;
    padding: 6px 9px 8px 9px !important;
  }

  .rce-mbox-photo--img__block {
    display: none;
  }

  .rce-mbox-file {
    padding-bottom: 0px !important;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rce-mbox-file > button {
    background-color: #e2f2ff !important;
    border-radius: 8px;
    min-height: 40px;
    width: 100%;
    padding: 0px 8px;
    svg {
      color: #ad5e99 !important;
    }
  }

  .rce-mbox-file--text {
    font-size: 14px;
    color: #000000;
  }

  .rce-mbox .rce-mbox-right .rce-mbox-file {
    background-color: #f7f7f7 !important;
  }
`;

export default StreamMobileChat;
