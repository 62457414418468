/* eslint-disable no-unused-expressions */
import { configure } from '..';
import { isFirefox } from '../helpers';
import { AGORA_APP_ID } from './config';
import { shareScreenToPatient } from '@/helpers/telecounsellingRoomSignalR';
import teleConsultNowDispatcher from '@/module/teleconsult-now/action';
// import teleConsultNowDispatcher from '@/module/telecounsel-queue/action';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { capitalize } from 'lodash';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

const DOM_MAIN_VIDEO_AREA = 'main_stream';
const DOM_SUB_VIDEO_AREA = 'sub_stream';
const DOM_SUB_USER_VIDEO_AREA = 'sub_user_stream';

class Agora4xInstance {
  constructor(appId) {
    this.rtc = {
      client: {
        instance: null,
        audioTrack: null,
        videoTrack: null,
      },
      remoteClient: {
        audioTrack: null,
        videoTrack: null,
      },
      shareScreenClient: {
        instance: null,
        screenTrack: null,
        uid: null,
      },
      localUserIds: [],
    };
    this.option = {
      appID: appId,
      channel: '',
      uid: null,
      token: null,
    };
    this.id = '';
    this.configInit = { mode: 'rtc', codec: 'h264' };
    this.configVideoTrack = { fit: 'contain' };
    this.initClient(appId);
  }

  initClient(appId = AGORA_APP_ID) {
    const { option, rtc, configInit, subscribeAudioAndVideoTracks } = this;
    option.appID = appId;

    rtc.client.instance = AgoraRTC.createClient(configInit);
    rtc.shareScreenClient.instance = AgoraRTC.createClient(configInit);
    subscribeAudioAndVideoTracks();
  }

  setOption = (option = {}) => {
    this.option = {
      ...this.option,
      ...option,
    };
  };

  joinChannel = async (channel = this.option.channel, shareScreen = false) => {
    try {
      const { option, rtc, createAndPublishLocalTracks, setOption } = this;
      setOption({
        channel,
      });

      let clientInstance = !shareScreen
        ? rtc.client.instance
        : rtc.shareScreenClient.instance;

      const uid = await clientInstance.join(
        option.appID,
        channel,
        null,
        option.uid
      );
      rtc.localUserIds.push(uid);
      if (shareScreen) rtc.shareScreenClient.uid = uid;

      // // Create local tracks, using microphone and camera
      createAndPublishLocalTracks(shareScreen, uid);

      console.log('join channel: ' + option.channel + ' success, uid: ' + uid);
    } catch (err) {
      console.error('client join failed', err);
    }
  };

  leaveChannel = async ({ leaveOnlyShareScreen }) => {
    try {
      const { rtc, configVideoTrack } = this;
      let client = rtc.client.instance;

      // If leave only user
      if (client && !leaveOnlyShareScreen) {
        // Destroy the audio and video tracks of client and remote client
        rtc.client.videoTrack?.stop();
        rtc.client.videoTrack?.close();
        rtc.client.audioTrack?.stop();
        rtc.client.audioTrack?.close();
        rtc.client.videoTrack = null;
        rtc.client.audioTrack = null;

        teleConsultNowDispatcher.setState('calling', false);
        teleConsultNowDispatcher.setState('isMuteAudio', false);
        teleConsultNowDispatcher.setState('isMuteVideo', false);
        teleConsultNowDispatcher.setState('isMuteSpeaker', false);
        teleConsultNowDispatcher.setState('isSharing', false);
        teleConsultNowDispatcher.setState('showChat', false);
        teleConsultNowDispatcher.setState('completedCallVisibility', true);
        teleConsultNowDispatcher.updateRemoteStream([]);

        // Leave the channel.
        await client.leave();
        console.log('client leaves channel success');
      }

      // If leave only share screen
      if (rtc.shareScreenClient.instance && rtc.shareScreenClient.screenTrack) {
        const isTracksArray = isArray(rtc.shareScreenClient.screenTrack);
        const isTracksEmpty = isEmpty(rtc.shareScreenClient.screenTrack);

        if (isTracksArray && !isTracksEmpty) {
          for (const trackScreen of rtc.shareScreenClient.screenTrack) {
            await trackScreen.stop();
            await trackScreen.close();
          }
        } else {
          rtc.shareScreenClient.screenTrack?.stop();
          rtc.shareScreenClient.screenTrack?.close();
        }

        rtc.shareScreenClient.uid = null;
        rtc.shareScreenClient.screenTrack = null;
        // play remote client on main screen, play client on sub screen after turn off share screen
        if (rtc.remoteClient?.audioTrack?.isPlaying)
          rtc.remoteClient.audioTrack?.play();

        if (rtc.remoteClient?.videoTrack?.isPlaying)
          rtc.remoteClient.videoTrack?.play(
            DOM_MAIN_VIDEO_AREA,
            configVideoTrack
          );

        teleConsultNowDispatcher.setState('isSharing', false);
        await rtc.shareScreenClient.instance.leave();
        rtc.client.videoTrack?.play(DOM_SUB_VIDEO_AREA, configVideoTrack);
      }
    } catch (err) {
      console.error('leaveChannel failed', err);
    }
  };

  createAndPublishLocalTracks = async (shareScreen, shareScreenUid) => {
    try {
      const {
        rtc,
        createTracks,
        playLocalTracks,
        subscribeShareScreenTrackEndedEvent,
      } = this;

      let tracks = await createTracks(shareScreen);

      let client = !shareScreen ? rtc.client : rtc.shareScreenClient;

      await client.instance.publish(tracks);

      //save track after publish success
      if (shareScreen && rtc.shareScreenClient.instance) {
        client.screenTrack = tracks;
        // Check if shareScreen track is array (return tracks with both audio and video)
        const isTracksArray = isArray(client.screenTrack);
        const isTracksEmpty = isEmpty(client.screenTrack);
        teleConsultNowDispatcher.setState('isSharing', true);
        shareScreenToPatient(shareScreenUid);

        // subscribe for track-ended event
        subscribeShareScreenTrackEndedEvent(
          tracks,
          isTracksArray && !isTracksEmpty,
          shareScreen
        );
      } else {
        client.audioTrack = tracks[0];
        client.videoTrack = tracks[1];
        playLocalTracks(shareScreen, client.videoTrack, client.audioTrack);
      }

      console.log('publish success!');
    } catch (err) {
      const { rtc } = this;
      let clientInstance = !shareScreen
        ? rtc.client.instance
        : rtc.shareScreenClient.instance;
      teleConsultNowDispatcher.setState('isSharing', false);
      await clientInstance.leave();
      console.log('publish failed');
      console.error(err);
    }
  };

  createTracks = async (shareScreen) => {
    if (!shareScreen) {
      // Capture and audio and video at one time
      const [audioTrack, videoTrack] =
        await AgoraRTC.createMicrophoneAndCameraTracks();
      audioTrack?.setEnabled(true);

      return [audioTrack, videoTrack];
    } else {
      let trackParams = {
        // Set the encoder configurations. For details, see the API description.
        encoderConfig: '1080p_1',
        // Set the video transmission optimization mode as prioritizing video quality.
        optimizationMode: 'detail',
      };
      if (isFirefox()) {
        trackParams.screenSourceType = 'window';
      }
      const screenTrack = await AgoraRTC.createScreenVideoTrack(
        trackParams,
        'auto'
      );
      return screenTrack;
    }
  };

  playLocalTracks = (shareScreen, videoTrack, audioTrack) => {
    const { rtc, configVideoTrack } = this;

    const isSharing = localStorage.getItem('isSharing');
    if (isSharing) {
      //stop local and remote tracks
      // rtc.client.audioTrack?.stop();
      // rtc.client.videoTrack?.stop();
      // rtc.remoteClient.audioTrack?.stop();
      // rtc.remoteClient.videoTrack?.stop();

      // play remote client on sub screen, play share screen on main screen
      if (audioTrack) {
        rtc.remoteClient.audioTrack?.play();
        audioTrack.play();
      }

      if (videoTrack) {
        rtc.client.videoTrack?.play(DOM_SUB_VIDEO_AREA, configVideoTrack);
        rtc.remoteClient.videoTrack?.play(
          DOM_SUB_USER_VIDEO_AREA,
          configVideoTrack
        );
        videoTrack.play(DOM_MAIN_VIDEO_AREA, configVideoTrack);
      }
    } else {
      videoTrack.play(DOM_SUB_VIDEO_AREA, configVideoTrack);
    }
  };

  subscribeShareScreenTrackEndedEvent = (
    track,
    isArrayChecked,
    shareScreen
  ) => {
    const { rtc, playLocalTracks } = this;

    if (isArrayChecked) {
      const videoTrack = track.find(
        (track) => track?.trackMediaType === 'video'
      );
      videoTrack.on('track-ended', async () => {
        teleConsultNowDispatcher.setState('isSharing', false);
        for (const trackScreen of rtc.shareScreenClient.screenTrack) {
          await trackScreen.stop();
          await trackScreen.close();
        }
        await rtc.shareScreenClient.instance.leave();
      });
      playLocalTracks(shareScreen, videoTrack);
    } else {
      track.on('track-ended', async () => {
        teleConsultNowDispatcher.setState('isSharing', false);
        await rtc.shareScreenClient.screenTrack?.stop();
        await rtc.shareScreenClient.screenTrack?.close();
        await rtc.shareScreenClient.instance.leave();
      });

      playLocalTracks(shareScreen, track);
    }
  };

  subscribeAudioAndVideoTracks = async () => {
    const { rtc, configVideoTrack } = this;
    let client = rtc.client.instance;

    // Will be triggered when a remote user publishes an audio or video track
    client.on('user-published', async (user, mediaType) => {
      // Initiate the subscription
      // Prevent subscribing to your own streams (normal and share screen)
      if (!rtc.localUserIds.includes(user.uid)) {
        await client.subscribe(user, mediaType);
      } else return;
      const streamId = localStorage.getItem('streamId');
      // If the remote user publishes a video track.
      if (mediaType === 'video') {
        const videoTrack = user.videoTrack;

        if (String(videoTrack?._userId) === streamId) {
          // Just needed to subscribe the user...
          await client.subscribe(user, mediaType);
          teleConsultNowDispatcher.setState('isSharing', true);

          videoTrack?.play(DOM_MAIN_VIDEO_AREA, configVideoTrack);
          rtc.remoteClient.videoTrack?.play(
            DOM_SUB_USER_VIDEO_AREA,
            configVideoTrack
          );
          rtc.client.videoTrack?.play(DOM_SUB_VIDEO_AREA, configVideoTrack);
        } else {
          await client.subscribe(user, mediaType);

          rtc.remoteClient.videoTrack = videoTrack;
          rtc.remoteClient.videoTrack?.play(
            DOM_MAIN_VIDEO_AREA,
            configVideoTrack
          );
          rtc.client.videoTrack?.play(DOM_SUB_VIDEO_AREA, configVideoTrack);
        }
      }

      // If the remote user publishes an audio track.
      if (mediaType === 'audio') {
        // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
        const audioTrack = user.audioTrack;
        audioTrack?.play();
        rtc.remoteClient.audioTrack = audioTrack;
      }

      teleConsultNowDispatcher.updateRemoteStream(
        [rtc.remoteClient.audioTrack, rtc.remoteClient.videoTrack].filter(
          (i) => i
        )
      );

      if (mediaType === 'video')
        teleConsultNowDispatcher.setState(
          `isPatientMute${capitalize(mediaType)}`,
          user.hasVideo
        );

      if (mediaType === 'audio')
        teleConsultNowDispatcher.setState(
          `isPatientMute${capitalize(mediaType)}`,
          user[`_audio_muted_`]
        );
    });

    // Will be triggered when a remote user unpublishes an audio or video track
    client.on('user-unpublished', (user, mediaType) => {
      if (mediaType === 'video')
        teleConsultNowDispatcher.setState(
          `isPatientMute${capitalize(mediaType)}`,
          user.hasVideo
        );

      if (mediaType === 'audio')
        teleConsultNowDispatcher.setState(
          `isPatientMute${capitalize(mediaType)}`,
          user[`_audio_muted_`]
        );
    });

    // Will be triggered when a remote user becomes offline.
    client.on('user-left', async (user) => {
      const id = user.uid;
      // check if remote user
      if (rtc.remoteClient.videoTrack?.getUserId() === id)
        rtc.remoteClient.videoTrack = null;
      if (rtc.remoteClient.audioTrack?.getUserId() === id)
        rtc.remoteClient.audioTrack = null;

      // check if share screen user

      //stop local and remote tracks

      // play remote client on main screen, play client on sub screen
      const streamId = localStorage.getItem('streamId');
      const isSharing = localStorage.getItem('isSharing');

      if (String(user?.uid) === streamId) {
        rtc.client.audioTrack?.stop();
        rtc.client.videoTrack?.stop();
        rtc.remoteClient.audioTrack?.stop();
        rtc.remoteClient.videoTrack?.stop();
        // rtc.shareScreenClient.uid = null;

        rtc.remoteClient.audioTrack?.play();
        rtc.remoteClient.videoTrack?.play(
          DOM_MAIN_VIDEO_AREA,
          configVideoTrack
        );
        rtc.client.videoTrack?.play(DOM_SUB_VIDEO_AREA, configVideoTrack);
        teleConsultNowDispatcher.setState('isSharing', false);
        localStorage.setItem('isSharing', false);
      } else {
        if (!isSharing) {
          rtc.client.audioTrack?.stop();
          rtc.client.videoTrack?.stop();
          rtc.remoteClient.audioTrack?.stop();
          rtc.remoteClient.videoTrack?.stop();
          rtc.shareScreenClient.uid = null;

          rtc.remoteClient.audioTrack?.play();
          rtc.remoteClient.videoTrack?.play(
            DOM_MAIN_VIDEO_AREA,
            configVideoTrack
          );
          rtc.client.videoTrack?.play(DOM_SUB_VIDEO_AREA, configVideoTrack);
        }

        rtc.client.videoTrack?.play(DOM_SUB_VIDEO_AREA, configVideoTrack);
      }
    });
  };

  muteSpeaker = async (isMute) => {
    try {
      const { rtc } = this;
      teleConsultNowDispatcher.setState('isMuteSpeaker', isMute);
      isMute
        ? rtc.remoteClient.audioTrack?.stop()
        : rtc.remoteClient.audioTrack?.play();
    } catch (err) {
      console.log('muteSpeaker failed');
      console.error(err);
    }
  };

  muteAudio = async (isMute) => {
    try {
      const { rtc } = this;
      teleConsultNowDispatcher.setState('isMuteAudio', isMute);
      isMute
        ? rtc.client.audioTrack.setMuted(true)
        : rtc.client.audioTrack.setMuted(false);
    } catch (err) {
      console.log('muteAudio failed');
      console.error(err);
    }
  };

  muteVideo = async (isMute) => {
    try {
      const { rtc } = this;
      teleConsultNowDispatcher.setState('isMuteVideo', isMute);
      rtc.client.videoTrack.setMuted(isMute);
    } catch (err) {
      console.log('muteVideo failed');
      console.error(err);
    }
  };

  switchCamera = async (cameraSwitched) => {
    try {
      const { rtc } = this;
      const videoTrackLabel = rtc.client.videoTrack.getTrackLabel();
      const cams = await AgoraRTC.getCameras();
      const currentCam = cams.find((item) => item.label === videoTrackLabel);
      teleConsultNowDispatcher.setState('cameraSwitched', false);
      cams.forEach((cam) => {
        if (cam.label !== currentCam.label && !cameraSwitched) {
          rtc.client.videoTrack.setDevice(currentCam.deviceId);
          teleConsultNowDispatcher.setState('cameraSwitched', true);
        }
      });
    } catch (err) {
      console.log('muteAudio failed');
      console.error(err);
    }
  };
}

export default class AgoraInstanceTeleconsultNow extends Agora4xInstance {
  static instance = Agora4xInstance.instance || new Agora4xInstance();
}

// Reset instance after end call
export const resetAgora4xInstance = () => {
  Agora4xInstance.instance = new Agora4xInstance();
};
