import { urlLabel } from '../../../enum/PermissionEnum';
import { roleType } from '../../../enum/RequestEnum';
import { getNationalNumber } from '../../../helpers';
import fetchHelper from '../../../helpers/FetchHelper';
import { saveToStorage } from '../../../helpers/StorageHelper';
import { stopConnection as wrSignalRStopConnection } from '../../../helpers/waitingRoomSignalR';
import { ROLE_TYPES } from '@/constants/roles';
import { stopConnection as appSignalRStopConnection } from '@/helpers/appointmentSignalR';
import { stopConnection as teleCounselSignalRStopConnection } from '@/helpers/telecounsellingRoomSignalR';
import customToast from '@/new-components/CustomNotification';
import globalDispatcher from '@/redux/global/actions';
import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  addNotifyToken:
    (payload, callback) =>
    async ({ Api }) => {
      const { status } = await Api.post(
        'Notifications/token',
        payload,
        {},
        {},
        false
      );
      if (status === 200) {
        callback && callback(true);
      } else {
        callback && callback(false);
      }
    },

  deleteNotifyToken:
    (payload) =>
    async ({ Api }) => {
      await Api.delete('Notifications/token', payload);
    },

  loginSuccess: (result, email) => ({ result, email }),

  login:
    (email, password, callback) =>
    async ({ Api }) => {
      const body = {
        email,
        password,
        clientType: 'Web',
      };
      const { result, message, status } = await Api.post(
        `Users/login`,
        body,
        undefined,
        undefined,
        false
      );
      if (status === 200) {
        callback(result, email);
      } else {
        if (message === 'User does not exist')
          callback('Wrong username and password', null);
        else callback(message);
      }
    },

  loginWithGoogle:
    (user, token, callback) =>
    async ({ Api }) => {
      const body = {
        token,
        role: 'Doctor',
      };
      const { result, status } = await Api.post(`Users/login-google`, body);
      if (status === 200) {
        callback(result);
      }
    },

  logoutSuccess: () => ({}),
  logout:
    () =>
    async ({ Api }) => {
      // localStorage.removeItem('roleName');
      localStorage.removeItem('persist:root');
      window.dispatchEvent(new Event('storage'));
      fetchHelper.addDefaultHeader('Authorization', ``);
      authDispatcher.logoutSuccess();
      wrSignalRStopConnection();
      appSignalRStopConnection();
      teleCounselSignalRStopConnection();
    },

  checkEmailExist:
    (email, roleName, callback, callbackError) =>
    async ({ Api }) => {
      const { result } = await Api.post('Users/checkAvaiableEmail', {
        email,
        role: roleName,
      });
      if (result) callback();
      else {
        callbackError && callbackError();
        customToast('error', 'Email already exists!');
      }
    },

  checkMobileExist:
    (mobile, countryCode, roleName, callback, callbackError) =>
    async ({ Api, getState }) => {
      let { roleName: role } = getState().auth.userInfo;
      const { result } = await Api.post('Users/checkAvaiableMobile', {
        mobile,
        countryCode,
        role: roleName || role,
      });
      if (result) callback();
      else {
        callbackError && callbackError();
        customToast('error', 'Phone Number already exists!');
      }
    },

  checkMrcExist:
    (mrc, callback) =>
    async ({ Api }) => {
      const { result } = await Api.post('Users/checkAvaiableMrc', { mrc });
      if (result) callback();
      else customToast('error', 'MRC already exists!');
    },

  registerSuccess: (generalInfo, rest) => ({ generalInfo, rest }),
  register:
    (
      email,
      password,
      mobile,
      countryCode,
      mcr,
      praticisingCertificate,
      pcExpiry,
      clinicId,
      firstName,
      lastName,
      credentials,
      history
    ) =>
    async ({ Api, customToast }) => {
      const body = {
        email,
        password,
        mobile: getNationalNumber(mobile, countryCode),
        countryCode,
        mcr,
        praticisingCertificate,
        pcExpiry,
        clinicId,
        firstName,
        lastName,
        credentials,
        roleType: roleType.Doctor,
      };
      const { status } = await Api.post('Doctors', body);
      if (status === 200) {
        customToast('success', 'Register success. Please waiting for approval');
        history.push(`/${urlLabel.home}`);
      }
    },
  registerInternal:
    (data, history) =>
    async ({ Api }) => {
      const { status } = await Api.post('Users/register', {
        ...data,
        roleType: 'Trainer',
      });
      authDispatcher.setState('loading', false);
      if (status === 200) {
        customToast('success', 'Register success. Please waiting for approval');
        history.push(`/${urlLabel.internalLogin}`);
      }
    },
  setState: (state, value) => ({ state, value }),
  getUserInfoSuccess: (roleType, userInfo) => ({ roleType, userInfo }),
  getUserInfo:
    (callback) =>
    async ({ Api }) => {
      authDispatcher.getServerTime();
      const { result, status } = await Api.get(`Users/userInfo`);
      if (status === 200) {
        const { roleType, ...rest } = result;
        callback && callback(result);
        authDispatcher.getUserInfoSuccess({ roleType }, rest);
        localStorage.setItem('roleName', result.roleName);
        if (result.roleName === ROLE_TYPES.PATIENT) {
          globalDispatcher.getPatientInfo();
        }
      }
    },

  getAllClinicsSuccess: (clinics) => ({ clinics }),
  getAllClinics:
    () =>
    async ({ Api }) => {
      const { result, status } = await Api.post('Clinics/filter', {});
      if (status === 200) authDispatcher.getAllClinicsSuccess(result);
    },

  sendResetPasswordEmail:
    (email) =>
    async ({ Api }) => {
      const { status } = await Api.post('Users/forgotPassword', {
        email,
        clientType: 'Web',
      });
      if (status === 200) {
        customToast(
          'success',
          `A reset password email has been sent to ${email}`
        );
      }
    },

  resetPassword:
    (token, userId, password, callback) =>
    async ({ Api }) => {
      const { status } = await Api.post('Users/changeForgotPassword', {
        token,
        userId,
        password,
      });
      if (status === 200) {
        customToast('success', 'Your password has been changed successfully!');
        callback && callback();
      }
    },

  resetPasswordOldUser:
    (token, password, callback) =>
    async ({ Api }) => {
      const { status } = await Api.put('Patients/change-old-user-pw', {
        token,
        password,
      });
      if (status === 200) {
        customToast('success', 'Your password has been changed successfully!');
        callback && callback();
      }
    },

  updateDoctorProfile:
    (data, history) =>
    async ({ Api }) => {
      const { status } = await Api.put(`Doctors/info`, data, undefined, {
        Authorization: `Bearer ${data.accessToken}`,
      });
      if (status === 200) {
        customToast(
          'success',
          'Your profile has been updated. Please waiting for approval'
        );
        setTimeout(() => history.push(`/`));
      }
    },
  getServerTime:
    () =>
    async ({ Api }) => {
      const { result, status } = await Api.get(`Configurations/server-time`);
      if (status === 200) {
        const serverTime = new Date(result);
        const clientTime = new Date();
        const offset = serverTime - clientTime;
        saveToStorage('serverTimeOffset', offset);
      }
    },
  sendOTP:
    (params, callback) =>
    async ({ Api }) => {
      const { status } = await Api.post('Users/send-otp', params);
      if (status === 200) {
        callback && callback(true);
      } else {
        callback && callback(false);
      }
    },
  verifyOTP:
    (params, callback) =>
    async ({ Api }) => {
      const { result } = await Api.put(`Users/verify-otp`, params);
      if (result) {
        callback && callback(true);
      } else {
        callback && callback(false);
      }
    },
  getAuthenticatorsInfo:
    (accessToken, callback) =>
    async ({ Api }) => {
      const { result } = await Api.get(`Authenticators/setup-info`, undefined, {
        Authorization: `Bearer ${accessToken}`,
      });
      if (result) {
        callback && callback(result);
      } else {
        callback && callback(false);
      }
    },
  validateAuthenticatorsCode:
    ({ accessToken, code }, callback) =>
    async ({ Api }) => {
      const { result } = await Api.post(
        `Authenticators/validate`,
        { code },
        undefined,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      if (result) {
        callback && callback(result);
      } else {
        callback && callback(false);
      }
    },
  getUserPasscode:
    () =>
    async ({ Api }) => {
      const { result } = await Api.get(`Users/passcode`);
      if (result) {
        authDispatcher.setState('userPasscode', result);
      }
    },
  confirmPatientAcknowledgesResult:
    (params, callback) =>
    async ({ Api }) => {
      const { status } = await Api.post('Patients/acknowledge', params);
      if (status === 200 || status === 400) {
        callback && callback(true);
      } else {
        callback && callback(false);
      }
    },

  loginPatient:
    (email, password, callback) =>
    async ({ Api }) => {
      const body = {
        email,
        password,
        clientType: 'Mobile',
      };
      const { result, message, status } = await Api.post(
        `Users/login`,
        body,
        undefined,
        undefined,
        false
      );
      if (status === 200) {
        callback(result, email);
      } else {
        if (message === 'User does not exist')
          callback('Wrong username and password', null);
        else callback(message);
      }
    },

  registerPatient:
    (params, callback) =>
    async ({ Api }) => {
      const { status } = await Api.post(`Patients/register`, params);
      if (status === 200) {
        callback && callback(true);
      } else {
        callback && callback(false);
      }
    },
  sendResetPasswordEmailPatient:
    (email, callback) =>
    async ({ Api }) => {
      const { status, result } = await Api.post('Users/forgotPassword', {
        email,
        clientType: 'Mobile',
      });
      if (status === 200) {
        callback && callback(result);
      }
    },
  checkPatientPreregistersAccount:
    (params, onSuccess) =>
    async ({ Api }) => {
      const { status, result } = await Api.post(
        'Patients/pre-register',
        params
      );
      if (status === 200) {
        onSuccess && onSuccess(result);
      }
    },

  sendEmailActive:
    (email, onSuccess, onFail) =>
    async ({ Api }) => {
      const { status, result, message } = await Api.post(
        'Patients/mail-active',
        {
          email,
        },
        {},
        {},
        false
      );
      if (status === 200) {
        onSuccess && onSuccess(result);
      } else {
        onFail && onFail(message);
      }
    },
};

const authDispatcher = synthesize('auth', mapDispatchToAC);
export default authDispatcher;
