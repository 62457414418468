import { CancelIcon } from '@/assets/svg';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500],
  },
});

const CustomDialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialog = withStyles((theme) => ({
  paper: {
    borderRadius: 16,
  },
  paperWidthXs: {
    maxWidth: 400,
  },
}))(Dialog);

const CustomButton = withStyles({
  root: {
    width: (props) => (props?.btnWidth ? props?.btnWidth : 166),
    height: 48,
    borderRadius: 8,

    fontSize: 18,
    fontWeight: 400,
    textTransform: 'unset',
    '&.red': {
      background: '#DB3D49',
    },
  },
  containedPrimary: {
    boxShadow: 'none',
  },
})(Button);

const CustomDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    paddingBottom: (props) =>
      props.show ? theme.spacing(2) : theme.spacing(5),
    textAlign: 'center',

    '& .main-content': {
      fontFamily: 'Lato',
      marginTop: 30,
      fontSize: 32,
      fontWeight: 700,
    },
    '& .sub-content': {
      paddingTop: 16,
      paddingLeft: (props) => (props.show ? 29 : 16),
      paddingRight: (props) => (props.show ? 29 : 16),
      fontSize: 16,
      color: '#666666',
    },
    '&:first-child': {
      paddingTop: theme.spacing(5),
    },
  },
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    justifyContent: 'center',
  },
})(DialogActions);

const ModalConfirmation = ({
  maxWidth = 'xs',
  open,
  onClose,
  onCancel,
  onClick,
  title,
  mainContent,
  subContent,
  subContent2,
  cancelLabel = 'Cancel',
  confirmLabel = 'Continue',
  Icon = <CancelIcon />,
  otherActionsContent,
  confirmColor,
  isShowActions = true,
  style,
  disableBackdropClick,
  btnWidth,
}) => {
  return (
    <CustomDialog
      maxWidth={maxWidth}
      fullWidth
      scroll="body"
      disableEnforceFocus
      disableBackdropClick={disableBackdropClick}
      open={open}
      onClose={onClose}
    >
      {title && (
        <CustomDialogTitle onClose={onClose}>{title}</CustomDialogTitle>
      )}

      <CustomDialogContent
        dividers={!!title}
        show={isShowActions ? 1 : 0}
        style={style}
      >
        {Icon && Icon}
        {mainContent && (
          <Typography className="main-content">{mainContent}</Typography>
        )}
        {subContent && (
          <Typography className="sub-content">{subContent}</Typography>
        )}
        {subContent2 && (
          <Typography className="sub-content" style={{ color: '#333333' }}>
            {subContent2}
          </Typography>
        )}
      </CustomDialogContent>
      {isShowActions ? (
        <CustomDialogActions style={{ paddingBottom: !!title ? 16 : 40 }}>
          {otherActionsContent ? (
            otherActionsContent()
          ) : (
            <>
              <CustomButton
                btnWidth={btnWidth}
                onClick={onCancel || onClose}
                variant="outlined"
                color="primary"
              >
                {cancelLabel}
              </CustomButton>
              <CustomButton
                btnWidth={btnWidth}
                onClick={onClick}
                variant="contained"
                color="primary"
                className={confirmColor}
              >
                {confirmLabel}
              </CustomButton>
            </>
          )}
        </CustomDialogActions>
      ) : null}
    </CustomDialog>
  );
};

export default ModalConfirmation;
