import Enum from './Enum';

export const roomStatus = Enum({
  Join: 'Join',
  Leave: 'Leave',
  Waiting: 'Waiting',
  Calling: 'Calling',
  EndCall: 'EndCall',
  ApproveIdentity: 'ApproveIdentity',
  RejectIdentity: 'RejectIdentity',
  AcceptCall: 'AcceptCall',
  RejectCall: 'RejectCall',
  WaitingRejoinCall: 'WaitingRejoinCall',
  SuccessRejoinCall: 'SuccessRejoinCall',
  ExpiredRejoinCall: 'ExpiredRejoinCall',
  CancelRejoinCall: 'CancelRejoinCall',
  PatientUnavailable: 'PatientUnavailable',
  PatientRejoinCall: 'PatientRejoinCall',
  CallInfo: 'CallInfo',
  CallProblem: 'CallProblem',
  PlatoError: 'PlatoError',
  Call: 'Call',
  ShareScreen: 'ShareScreen',
  DoctorRejoinCall: 'DoctorRejoinCall',
});
