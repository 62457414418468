import companyDispatcher from '../company/action';
import { FIELD_ARRAY_VALUE } from '../company/constants';
import { formatPrice } from '../company/helpers';
import { formatData } from '../package-management/utils';
import AvailableServicesStep from './components/ProjectInfo/AvailableServicesStep';
import GeneralInformationStep from './components/ProjectInfo/GeneralInformationStep';
import ScreeningLocationsStep from './components/ProjectInfo/ScreeningLocationsStep';
import {
  BILLING_TYPE,
  PROJECT_STEPS,
  STEPS_CONTROL,
  refactorListData,
} from './constants';
import { isBillableProject } from './utils';
import { CloseIcon } from '@/assets/svg';
import { formatOldData } from '@/helpers';
import validateData from '@/helpers/validationHelpers/validationSchema';
import customToast from '@/new-components/CustomNotification';
import CustomStepper from '@/new-components/CustomStepper';
import { StepperButton } from '@/new-components/CustomStepper/styled';
import globalDispatcher from '@/redux/global/actions';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { capitalize, map, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify/dist';

const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    color: '#333',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  mainWrapper: {
    height: 'calc(100vh - 200px)',
    overflow: 'auto',
  },
});

const CustomDrawerTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <Box className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Box>
  );
});

const CreateAndEditProject = ({
  companyID,
  onClose,
  isCreate,
  selectedItem,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [listOrganisationCodes, setListOrganisationCodes] = useState([]);
  const [errors, setErrors] = useState({});
  const [isDisableNext, setDisabledNext] = useState(true);
  const [loading, setLoading] = useState(false);

  const { consents: listConsents = [] } = useSelector(
    (state) => state.globalState
  );

  const classes = useStyles();

  const getCurrentTimeIsValid = () => {
    // Before 9:00 pm return current day
    // After 9:00 pm  return next day
    if (moment().get('hour') <= 21) {
      return moment();
    }
    return moment().add(1, 'day');
  };

  const [formValue, setFormValue] = useState({
    startDate: getCurrentTimeIsValid(),
    endDate: getCurrentTimeIsValid(),
    // lstTests: [],
    lstProfiles: [],
    lstStaffTypeItems: [],
    lstProjectCustomFields: [],
    lstPackages: [],
    lstHealthScreenings: [],
    consentId: '',
    isPersonal: false,
    isSuppression: false,
    name: '',
    companyId: companyID,
    billingType: BILLING_TYPE.Default,
    hasServedAppointment: false,
    payAtScreening: false,

    /** computed list tests */
    lstTests: [],
    lstMeasurements: [],
    lstProcedures: [],
    lstRadiologies: [],
    lstVaccinations: [],
    lstReviews: [],

    labProvider: '',
    // jsonArrayStaffItems: '',
    // staffTypeLabel: '',
    // staffValueType: 'Percent'
  });

  const [listTests, setListTests] = useState({
    lstTests: [],
    lstMeasurements: [],
    lstProcedures: [],
    lstRadiologies: [],
    lstVaccinations: [],
    lstReviews: [],
  });

  const formatProjectCategory = (data) => {
    const result = map(data, (item) => ({
      ...item,
      value: FIELD_ARRAY_VALUE.includes(item.customFieldType)
        ? JSON.stringify(item.value)
        : null,
    }));

    return result;
  };

  const onSaveProjectInfo = async () => {
    try {
      await validateData('projectSchema', formValue, () => {
        setLoading(true);
        //merge sub services to list tests
        const lstTests = refactorListData(formValue);

        const lstProjectCustomFields = formValue.lstProjectCustomFields.map(
          (item, index) => ({ ...item, order: index })
        );

        //call api
        const data = {
          ...formValue,
          lstTests: formatPrice(lstTests),
          lstProfiles: formatPrice(formValue.lstProfiles),
          lstPackages: formatPrice(formValue.lstPackages),
          lstProjectCustomFields: formatProjectCategory(lstProjectCustomFields),
          companyId: formValue?.companyId,
          startDate: moment(formValue.startDate).startOf('D'),
          endDate: moment(formValue.endDate).endOf('D'),
        };

        onSubmit(data);
      });
    } catch (err) {
      setErrors({ ...errors, ...err });
    }
  };

  const renderToast = (isCreate, data, mainType) => {
    return customToast(
      'success',
      <span>
        <strong style={{ fontWeight: 600 }}>{data}</strong> has been
        successfully {isCreate ? 'created' : 'updated'}.
      </span>,
      isCreate ? `New ${mainType} created` : ` ${capitalize(mainType)} updated`
    );
  };

  const onSubmit = (data) =>
    isCreate
      ? companyDispatcher.createProject(data, () => {
          setLoading(false);
          onClose();
          renderToast(isCreate, data.name, 'project');
        })
      : companyDispatcher.editProject(selectedItem.id, data, () => {
          setLoading(false);
          onClose();
          renderToast(isCreate, data.name, 'project');
        });

  const copyLinkClipboard = async (text) => {
    if (!navigator?.clipboard) {
      customToast('error', 'Clipboard not supported!', {
        position: 'bottom-right',
        theme: 'colored',
      });
      return;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      customToast('success', 'Copy successful!');
    } catch (error) {
      customToast('error', 'Copy failed!', {
        position: 'bottom-right',
        theme: 'colored',
      });
    }
  };

  useEffect(() => {
    if (!isCreate) {
      companyDispatcher.getProjectByID(selectedItem?.id, (result) => {
        //filter list tests to sub service
        // let filteredTests = { ...listTests };
        // const { lstTests } = result;
        // SUB_MENU.forEach(item => {
        //   filteredTests[item] = find(lstTests, ['category', item])?.items || [];
        // });

        //convert list category value

        const lstProjectCustomFields = result.lstProjectCustomFields
          .map((item) =>
            FIELD_ARRAY_VALUE.includes(item.customFieldType)
              ? {
                  ...item,
                  value: JSON.parse(item.value),
                }
              : item
          )
          .map((item, index) => ({ ...item, order: index }));

        const {
          lstTests,
          lstMeasurements,
          lstProcedures,
          lstRadiologies,
          lstVaccinations,
          lstReviews,
        } = formatData(result);

        setFormValue({
          ...formValue,
          ...result,
          lstProjectCustomFields,

          lstTests,
          lstMeasurements,
          lstProcedures,
          lstRadiologies,
          lstVaccinations,
          lstReviews,
        });
      });
    }

    companyDispatcher.getProjectOrganization((result) =>
      setListOrganisationCodes(result)
    );
    globalDispatcher.getListConsentForm();
  }, []);

  useEffect(() => {
    /**
     *  To validate General & Screening locations required fields,
     *  will break out if missing data in one of required fields
     *
     * Required fields:
     *  1. Select screening type
     *  2. Project name
     *  3. Organization code
     *  4. Start date
     *  5. End date
     */
    let isMissingRequiredData = false;
    const requiredGeneralFields = [
      'name',
      'startDate',
      'endDate',
      'isPersonal',
      'labProvider',
    ];
    for (const [key, value] of Object.entries(formValue)) {
      if (
        (requiredGeneralFields.includes(key) &&
          (value === '' || value === undefined || value === null)) ||
        (currentStep === 1 && formValue.lstHealthScreenings?.length === 0)
      ) {
        isMissingRequiredData = true;
        break;
      }
    }

    let isInvalidPriceAndBillableAmount = false;
    if (currentStep === 2 && isBillableProject(formValue.billingType)) {
      const newErrors = [
        'lstPackages',
        'lstProfiles',
        'lstMeasurements',
        'lstProcedures',
        'lstRadiologies',
        'lstReviews',
        'lstTests',
        'lstVaccinations',
      ]
        .map((key) => ({
          [key]: (formValue[key] || []).reduce((previous, current) => {
            if (current.billableAmount > current.price) {
              return {
                ...previous,
                [current.id]: {
                  billableAmount: ' ',
                  price: ' ',
                },
              };
            } else {
              return previous;
            }
          }, {}),
        }))
        .reduce((previous, current) => {
          return Object.assign(previous, current);
        }, {});

      isInvalidPriceAndBillableAmount = Object.values(newErrors).some(
        (error) => !isEmpty(error)
      );

      setErrors((errors) => ({
        ...errors,
        ...newErrors,
      }));
    }

    setDisabledNext(isMissingRequiredData || isInvalidPriceAndBillableAmount);
  }, [currentStep, formValue]);

  const renderListTabs = [
    {
      label: 'General Information',
      component: (
        <GeneralInformationStep
          errors={errors}
          setErrors={setErrors}
          formValue={formValue}
          setFormValue={setFormValue}
          listConsentForms={listConsents}
          copyLinkClipboard={copyLinkClipboard}
        />
      ),
    },
    {
      label: 'Screening locations',
      component: (
        <ScreeningLocationsStep
          minDate={formValue.startDate}
          maxDate={formValue.endDate}
          errors={errors}
          data={formValue.lstHealthScreenings}
          submitData={(value) =>
            setFormValue({ ...formValue, lstHealthScreenings: value })
          }
          listOrganisationCodes={listOrganisationCodes}
        />
      ),
    },
    {
      label: 'Available services',
      component: (
        <AvailableServicesStep
          errors={errors}
          setErrors={setErrors}
          formValue={formValue}
          setFormValue={setFormValue}
        />
      ),
    },
  ];

  const StepperButtons = () => (
    <Box display="flex" justifyContent="flex-end" p={1.25}>
      {currentStep !== 0 && (
        <StepperButton
          className="secondary"
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          Back
        </StepperButton>
      )}

      <StepperButton
        disabled={isDisableNext || loading}
        onClick={() => {
          if (currentStep === PROJECT_STEPS.length - 1) {
            onSaveProjectInfo();
          } else setCurrentStep(currentStep + 1);
        }}
      >
        {currentStep === PROJECT_STEPS.length - 1
          ? isCreate
            ? 'Create'
            : 'Save'
          : 'Next'}
      </StepperButton>
    </Box>
  );

  return (
    <Paper square>
      <CustomDrawerTitle onClose={onClose}>
        {isCreate ? 'Create new' : 'Edit'} project
      </CustomDrawerTitle>

      <CustomStepper
        steps={PROJECT_STEPS}
        stepsControl={STEPS_CONTROL}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        allowSkip
        handleSubmit={() => {}}
        renderButtons={StepperButtons}
      >
        <main className={classes.mainWrapper}>
          {renderListTabs[currentStep].component}
        </main>
      </CustomStepper>
      <ToastContainer />
    </Paper>
  );
};

export default CreateAndEditProject;
