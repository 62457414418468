import { AppointmentIcon, CloseCircle } from '../../../../assets/svg';
import { ENTER_KEY } from '../../../common';
import authDispatcher from '../../action/auth';
import { urlLabel } from '@/enum/PermissionEnum';
import validateData from '@/helpers/validationHelpers/validationSchema';
import CustomButton from '@/new-components/CustomButton';
import CustomLoadingButton from '@/new-components/CustomLoadingButton';
import ModalConfirmationMobile from '@/new-components/CustomModal/ModalConfirmationMobile';
import customToast from '@/new-components/CustomNotification';
import {
  Box,
  IconButton,
  TextField,
  Typography,
  withStyles,
  InputLabel,
  InputAdornment,
} from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

const CustomTitle = withStyles({
  root: {
    fontSize: 18,
    fontFamily: 'Lato',
    fontWeight: 700,
    color: '#191919',
  },
})(Typography);

const CustomBigTitle = withStyles({
  root: {
    fontSize: 32,
    fontFamily: 'Lato',
    fontWeight: 700,
    color: '#191919',
  },
})(Typography);

const CustomInputLabel = withStyles({
  root: {
    color: '#666666',
    marginBottom: 8,

    fontWeight: 600,
    textAlign: 'left',
  },
})(InputLabel);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16,
    },

    '& .MuiInputBase-inputMultiline': {
      '&.MuiInputBase-input': {
        marginLeft: 0,
      },
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiTypography-root': {
        fontSize: 16,
        color: '#333333',
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
      },
      '& input': {
        marginLeft: 0,
      },
    },
    '& .MuiFormHelperText-root': {
      alignItems: 'center',
      display: 'flex',
      fontFamily: 'OpenSans',
      fontSize: 12,
      gap: 6,
      marginLeft: 2,

      '& .MuiSvgIcon-root': {
        fontSize: 'unset',
      },
    },
  },
})(TextField);

const PatientForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();
  const handleSubmit = async () => {
    try {
      await validateData('forgotPasswordSchema', { email }, ({ email }) => {
        authDispatcher.sendResetPasswordEmailPatient(email, (result) => {
          if (result?.isSuccess) setShowModal(true);
        });
      });
    } catch (errors) {
      setError(errors?.email || '');
    }
  };

  const handleResendSubmit = async () => {
    try {
      await validateData('forgotPasswordSchema', { email }, ({ email }) => {
        authDispatcher.sendResetPasswordEmailPatient(email, (result) => {
          if (result?.isSuccess) {
            customToast(
              'error',
              'Please check your mail box to reset your password.'
            );
          }
        });
      });
    } catch (errors) {
      setError(errors?.email || '');
    }
  };
  const goLogin = () => {
    history.push(`/${urlLabel.patientLogin}`);
  };

  if (
    process.env.REACT_APP_MINC_DOMAIN &&
    window.location.host === process.env.REACT_APP_MINC_DOMAIN &&
    window.location.hash === `#/${urlLabel.patientForgotPassword}`
  ) {
    return <Redirect to={`${urlLabel.forgotPassword}`} />;
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        p={2.5}
        height={'100dvh'}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box flex={1}>
            <IconButton onClick={goLogin}>
              <KeyboardArrowLeft />
            </IconButton>
          </Box>
          <Box flex={2} textAlign="center">
            <CustomTitle>Forgot password</CustomTitle>
          </Box>
          <Box flex={1}></Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height={'100%'}
        >
          <Box textAlign="center" mt={5}>
            <CustomBigTitle>Reset password</CustomBigTitle>
            <Box mt={2} fontFamily="OpenSans" fontSize={16}>
              Enter your email to reset the password
            </Box>
            <Box maxWidth={600} textAlign="left" mt={8}>
              <CustomInputLabel style={{ marginTop: 24 }}>
                Email
              </CustomInputLabel>
              <CustomTextField
                id="email"
                fullWidth
                variant="outlined"
                placeholder="email@address.com"
                value={email || ''}
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value.trim())}
                onKeyDown={(e) => e.keyCode === ENTER_KEY && handleSubmit()}
                error={!!error}
                helperText={error}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{
                          visibility: !isEmpty(email) ? 'visible' : 'hidden',
                        }}
                        onClick={(event) => {
                          const target = document.getElementById('email');

                          if (event.target !== target) {
                            target.focus();
                            target.click();
                          }
                          setEmail('');
                        }}
                      >
                        <CloseCircle
                          height={20}
                          width={20}
                          style={{
                            color: '#666666',
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <Box width="100%" textAlign="center" py={3}>
            <CustomLoadingButton
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              fullWidth
              style={{ width: '100%' }}
              disabled={isEmpty(email)}
            >
              Send reset link
            </CustomLoadingButton>
          </Box>
        </Box>
      </Box>

      {showModal && (
        <ModalConfirmationMobile
          open={showModal}
          mainContent="Check your email"
          height={'calc(100vh - 212px)'}
          title={
            <Box
              height={40}
              width="100%"
              textAlign="center"
              position="relative"
            >
              <IconButton
                onClick={() => setShowModal(false)}
                style={{ position: 'absolute', top: 6, left: 0 }}
              >
                <KeyboardArrowLeft />
              </IconButton>
              <CustomTitle style={{ lineHeight: 2.5 }}>
                Forgot password
              </CustomTitle>
            </Box>
          }
          subContent={
            <Box color="#666666" fontSize={16} px={7} mt={2} textAlign="center">
              <p>We have sent you a link to reset your password.</p>
            </Box>
          }
          otherActionsContent={() => (
            <Box width="100%" textAlign="center">
              <CustomButton
                onClick={goLogin}
                variant="contained"
                color="primary"
                style={{ width: '100%', height: 48 }}
                margin="0 16px"
              >
                Back to Sign in
              </CustomButton>
              <Box
                mt={2}
                fontFamily="OpenSans"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <span>Can’t find it in your inbox?</span>
                <CustomButton
                  onClick={handleResendSubmit}
                  style={{
                    width: 70,
                    minWidth: 'unset',
                    height: 20,
                    fontWeight: 600,
                    color: '#AD5E99',
                    cursor: 'pointer',
                  }}
                >
                  Resend
                </CustomButton>
              </Box>
            </Box>
          )}
          Icon={<AppointmentIcon style={{ marginTop: 100 }} />}
        />
      )}
    </>
  );
};

export default PatientForgotPassword;
