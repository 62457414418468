import { ClearComponent } from './ClearComponent';
import { CheckboxActive, CheckboxDefault } from '@/assets/svg';
import CustomDate from '@/new-components/CustomDate';
import CustomLoadingButton from '@/new-components/CustomLoadingButton';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import {
  Box,
  TextField,
  Typography,
  InputLabel,
  withStyles,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Divider } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';

export const IDENTITY_TYPE = [
  {
    key: 'NRIC',
    value: 'NRIC/FIN',
  },
  {
    key: 'Passport',
    value: 'Passport',
  },
];

const CustomTitle = withStyles({
  root: {
    fontSize: 16,
    fontWeight: 600,

    color: '#000000',
  },
})(Typography);

const CustomInputLabel = withStyles({
  root: {
    color: '#666666',
    marginBottom: 8,

    fontWeight: 600,
  },
})(InputLabel);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16,
    },

    '& .MuiInputBase-inputMultiline': {
      '&.MuiInputBase-input': {
        marginLeft: 0,
      },
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiTypography-root': {
        fontSize: 16,
        color: '#333333',
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
      },
      '& input': {
        marginLeft: 0,
      },
    },
    '& .MuiFormHelperText-root': {
      alignItems: 'center',
      display: 'flex',
      fontFamily: 'OpenSans',
      fontSize: 12,
      gap: 6,
      marginLeft: 2,

      '& .MuiSvgIcon-root': {
        fontSize: 'unset',
      },
    },
  },
})(TextField);

const CustomToggleButtonGroup = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderLeft: '1px solid #E1E1E1',
      borderRadius: 8,
    },
    '&:first-child': {
      borderRadius: 8,
    },
  },
}))(ToggleButtonGroup);

const CustomToggleButton = withStyles((theme) => ({
  root: {
    flex: 1,
    border: '1px solid #E1E1E1',
    color: '#3333333',
    textTransform: 'unset',
    fontSize: 16,

    '&.Mui-selected': {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.light,

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    '&.error': {
      border: `1px solid ${theme.palette.feedback.error}`,
    },
  },
}))(ToggleButton);

const CompleteProfile = ({
  loading,
  formValue,
  errors,
  handleSubmit,
  handleChangeForm,
  unitNumber,
  handleChangeUnitNumber,
  handleClearForm,
}) => {
  const isEmptyParams = useMemo(() => {
    const filterKeys = [
      'fullName',
      'dateOfBirth',
      'gender',
      'identityType',
      'identityNumber',
      'postalCode',
      'address',
    ];

    let valid = false;
    // Unit Number
    if (
      !formValue.ignoredUnitNumber &&
      isEmpty(formValue.floor) &&
      isEmpty(formValue.unitNumber)
    ) {
      valid = true;
    } else if (
      !formValue.ignoredUnitNumber &&
      (!(
        (!isEmpty(formValue.floor) && !isEmpty(formValue.unitNumber)) ||
        (isEmpty(formValue.floor) && isEmpty(formValue.unitNumber))
      ) ||
        (!isEmpty(formValue.floor) &&
          !isEmpty(formValue.unitNumber) &&
          (formValue.floor?.length !== 2 ||
            !(unitNumber.indexOf('#') === 0 && unitNumber.indexOf('-') === 3))))
    ) {
      // Must be enter floor and unit number together and
      // If enter both floor and number, musst be follow format #[Floor(2 characters)]-[Unit]
      valid = true;
    }

    if (formValue.postalCode?.length < 6) valid = true;

    const checkValid = filterKeys.some((key) => !formValue[key]);
    return checkValid || valid;
  }, [formValue, unitNumber]);

  const handleInputUnitNumber = (e) => {
    if (
      formValue.floor &&
      e.target.value.indexOf('#') === 0 &&
      e.target.value.length === 3 &&
      !unitNumber.includes('-')
    ) {
      // Need input #[2 characters] and the dash will be auto-gen
      e.target.value = e.target.value.toString() + '-';
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      mt={5}
      alignItems="center"
      overflow="hidden"
    >
      <Box
        maxWidth={600}
        width="100%"
        mb={2.5}
        className="container-scroll"
        height="calc(100vh - 200px)"
      >
        <CustomTitle>Personal details</CustomTitle>
        <CustomInputLabel style={{ marginTop: 24 }}>Full name</CustomInputLabel>
        <CustomTextField
          id="fullName"
          fullWidth
          variant="outlined"
          placeholder="Enter full name as per NRIC"
          value={formValue.fullName || ''}
          onChange={handleChangeForm('fullName')}
          error={!!errors.fullName}
          helperText={errors.fullName}
          InputProps={{
            endAdornment: (
              <ClearComponent
                id="fullName"
                value={formValue.fullName}
                onClear={() => handleClearForm('fullName')}
              />
            ),
          }}
        />
        <CustomInputLabel style={{ marginTop: 24 }}>
          Date of birth
        </CustomInputLabel>
        <CustomDate
          selectedDate={formValue.dateOfBirth}
          isFullWidth
          placeholder="DD/MM/YYYY"
          clearable={false}
          onChange={handleChangeForm('dateOfBirth')}
          maxDate={moment()}
          style={{ height: 48, fontFamily: 'OpenSans', fontSize: 16 }}
        />
        <CustomInputLabel style={{ marginTop: 24 }}>Gender</CustomInputLabel>
        <CustomToggleButtonGroup
          exclusive
          value={formValue?.gender}
          onChange={handleChangeForm('gender')}
        >
          <CustomToggleButton
            value="Female"
            className={errors.gender ? 'error' : ''}
          >
            Female
          </CustomToggleButton>
          <CustomToggleButton
            value="Male"
            className={errors.gender ? 'error' : ''}
          >
            Male
          </CustomToggleButton>
        </CustomToggleButtonGroup>
        <CustomInputLabel style={{ marginTop: 24 }}>ID type</CustomInputLabel>
        <CustomSelectContainer
          data={IDENTITY_TYPE || []}
          height="48px"
          fontSize="16px"
          isMutiple={false}
          hideClear
          colorInput="#000000de"
          placeholder="Select"
          renderValue={
            formValue.identityType
              ? IDENTITY_TYPE.find((it) => it.key === formValue.identityType)
                  ?.value
              : 'Select'
          }
          filterValue={formValue.identityType || ''}
          onChange={handleChangeForm('identityType')}
        />

        <CustomInputLabel style={{ marginTop: 24 }}>ID number</CustomInputLabel>
        <CustomTextField
          id="identityNumber"
          fullWidth
          variant="outlined"
          placeholder="Enter number"
          value={formValue.identityNumber || ''}
          onChange={handleChangeForm('identityNumber')}
          error={!!errors.identityNumber}
          helperText={errors.identityNumber}
          InputProps={{
            endAdornment: (
              <ClearComponent
                id="identityNumber"
                value={formValue.identityNumber}
                onClear={() => handleClearForm('identityNumber')}
              />
            ),
          }}
        />
        <Box my={2}>
          <Divider light />
        </Box>
        <CustomTitle>Address details</CustomTitle>
        <CustomInputLabel style={{ marginTop: 24 }}>Address</CustomInputLabel>
        <CustomTextField
          id="address"
          fullWidth
          variant="outlined"
          placeholder="Enter full address"
          value={formValue.address || ''}
          onChange={handleChangeForm('address')}
          error={!!errors.address}
          helperText={errors.address}
          InputProps={{
            endAdornment: (
              <ClearComponent
                id="address"
                value={formValue.address}
                onClear={() => handleClearForm('address')}
              />
            ),
          }}
        />
        <CustomInputLabel style={{ marginTop: 24 }}>
          Unit number
        </CustomInputLabel>
        <CustomTextField
          id="unitNumber"
          fullWidth
          variant="outlined"
          placeholder="#12-234"
          value={unitNumber || ''}
          onChange={handleChangeUnitNumber}
          onInput={handleInputUnitNumber}
          error={!!errors.unitNumber}
          helperText={errors.unitNumber}
          disabled={formValue?.ignoredUnitNumber}
          InputProps={{
            endAdornment: (
              <ClearComponent
                id="unitNumber"
                value={unitNumber}
                onClear={() => handleClearForm('unitNumber')}
              />
            ),
          }}
        />

        <FormControlLabel
          control={
            <Checkbox
              style={{ marginLeft: 3 }}
              checked={formValue?.ignoredUnitNumber}
              icon={<CheckboxDefault />}
              checkedIcon={<CheckboxActive />}
              color="primary"
              onChange={handleChangeForm('ignoredUnitNumber')}
            />
          }
          label="I don't have a unit number"
        />
        <CustomInputLabel style={{ marginTop: 24 }}>
          Postal code
        </CustomInputLabel>
        <CustomTextField
          id="postalCode"
          fullWidth
          variant="outlined"
          placeholder="Enter code"
          value={formValue.postalCode || ''}
          onChange={handleChangeForm('postalCode')}
          error={!!errors.postalCode}
          helperText={errors.postalCode}
          style={{ marginBottom: 24 }}
          onInput={(e) => {
            e.target.value = e.target.value.toString().slice(0, 6);
          }}
          InputProps={{
            endAdornment: (
              <ClearComponent
                id="postalCode"
                value={formValue.postalCode}
                onClear={() => handleClearForm('postalCode')}
              />
            ),
          }}
        />
      </Box>
      <Box width={'100%'}>
        <CustomLoadingButton
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          fullWidth
          loading={loading}
          style={{ width: '100%' }}
          disabled={isEmptyParams || loading}
        >
          Next
        </CustomLoadingButton>
      </Box>
    </Box>
  );
};

export default CompleteProfile;
